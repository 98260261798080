import ACTIONS from "./actionTypes"

export const setComparisonInsurer = insurer => ({
  type: ACTIONS.SET_COMPARE_INSURER,
  payload: insurer,
})

export const clearComparisonInsurer = () => ({
  type: ACTIONS.CLEAR_COMPARE_INSURER,
})
