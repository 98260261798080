import Image from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
import React from "react"

const Logo = () => {
  const { logo } = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo.png" }) {
        sharp: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)
  return <Image fluid={logo.sharp.fluid} alt="logo" />
}

export default Logo
