import React from "react"
import { css } from "@emotion/core"
import { useIntl } from "react-intl"

import RegVideo from "./video"
import Title from "./Title"

const WatchShortVid = () => {
  return (
    <section
      css={css`
        padding: 5rem 0;
        text-align: center;
      `}
    >
      <div
        css={css`
          text-align: center;
        `}
      >
        <div>
          <p
            css={css`
              color: var(--clr-primary-500);
            `}
          >
            {useIntl().formatMessage({ id: "home.vidsup" })}
          </p>
          <Title title={useIntl().formatMessage({ id: "home.vidcap" })} />
        </div>
        <RegVideo />
      </div>
    </section>
  )
}

export default WatchShortVid
