import styled from "@emotion/styled"

const CompareCheckbox = styled.div`
  input[type="checkbox"] {
    --active: var(--clr-neutral-white);
    --active-inner: var(--clr-primary-300);
    --focus: 2px rgba(39, 94, 254, 0);
    --border: rgba(9, 31, 82, 0.4);
    --border-hover: var(--clr-primary-300);
    --background: var(--clr-neutral-100);
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 1.5rem;
    width: 1.5rem;
    outline: none;
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin: 0 0.5rem 0 0;
    cursor: pointer;
    border: 1px solid var(--bc, var(--border));
    border-radius: var(--border-radius);
    background: var(--b, var(--background));
    transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
    pointer-events: ${props => props.disabled && "none"};

    &:after {
      content: "";
      display: block;
      left: 0;
      top: 0;
      position: absolute;
      transition: transform var(--d-t, 0.3s) var(--d-t-e, ease),
        opacity var(--d-o, 0.2s);
      opacity: var(--o, 0);
      width: 0.4rem;
      height: 0.75rem;
      border: 2px solid var(--active-inner);
      border-top: 0;
      border-left: 0;
      left: 0.5rem;
      top: 0.25rem;
      transform: rotate(var(--r, 20deg));
    }
    &:checked {
      --b: var(--active);
      --bc: var(--active);
      --d-o: 0.3s;
      --d-t: 0.6s;
      --d-t-e: cubic-bezier(0.2, 0.85, 0.32, 1.2);
      --o: 1;
      --r: 43deg;
    }
    &:hover {
      &:not(:checked) {
        &:not(:disabled) {
          --bc: var(--border-hover);
        }
      }
    }
  }
`

export default CompareCheckbox
