import React from "react"
import { css } from "@emotion/core"

const Title = ({
  title,
  titleFont,
  fontColor,
  barColor,
  textTransform,
  subcap,
}) => {
  return (
    <div
      css={css`
        text-align: center;
        width: 90%;
        margin: 0 auto 4rem auto;
      `}
    >
      <h2
        css={css`
          font-family: ${titleFont};
          color: ${fontColor};
          text-transform: ${textTransform};
        `}
      >
        {title || "default title"}
      </h2>
      <div
        css={css`
          width: 5rem;
          height: 0.25rem;
          margin-bottom: 1.25rem;
          margin-left: auto;
          margin-right: auto;
          background-color: ${barColor || "var(--clr-primary-100)"};
        `}
      ></div>
      <p
        css={css`
          font-size: 0.9rem;
          color: var(--clr-neutral-400);
        `}
      >
        {subcap}
      </p>
    </div>
  )
}

export default Title
