import ACTIONS from "./actionTypes"

export const setCoverage = coverage => ({
  type: ACTIONS.SET_COVERAGE,
  payload: coverage,
})

export const setDeductible = deductible => ({
  type: ACTIONS.SET_DEDUCTIBLE,
  payload: deductible,
})

export const setUnavailableCoverages = coverages => ({
  type: ACTIONS.SET_UNAVAILABLE_COVERAGES,
  payload: coverages,
})

export const setUnavailableDeductibles = deductibles => ({
  type: ACTIONS.SET_UNAVAILABLE_DEDUCTIBLES,
  payload: deductibles,
})

export const setAllData = all => ({
  type: ACTIONS.SET_ALL_COVERAGES_DEDUCTIBLES,
  payload: all,
})
