import React from "react"
import { useSelector } from "react-redux"
import { css } from "@emotion/core"
import styled from "@emotion/styled"
import { navigate } from "gatsby"
import { useIntl } from "react-intl"

import {
  CardContainerStyled,
  SecondaryButton,
  LabelStyled,
  TravellerDisplay,
} from "."

const TravellersDisplayCard = ({ travelers, noEditBtn }) => {
  const intl = useIntl()
  let travellers = useSelector(state => state.travellers)
  travellers = travelers || travellers

  return (
    <CardContainerStyled
      maxWidth="700px"
      css={css`
        margin: 0 auto;
      `}
    >
      <Wrapper>
        <div className="topPortion">
          <LabelStyled fontSize="1.25rem" marginBottom="0">
            {intl.formatMessage({ id: "stp.travellers" })}
          </LabelStyled>
          <div>
            {!noEditBtn && (
              <SecondaryButton
                buttonColor="var(--clr-primary-100)"
                textSize="0.875rem"
                textWeight="700"
                padding="0.5rem 1rem"
                onClick={() => navigate("/get-quote-travellers")}
              >
                {intl.formatMessage({ id: "btn.edit" })}
              </SecondaryButton>
            )}
          </div>
        </div>
        <div className="btmPortion">
          {travellers.map((traveller, idx) => {
            if (travellers.length > 0)
              return (
                <TravellerDisplay
                  role={intl.formatMessage({
                    id: traveller.relationship_status,
                  })}
                  key={idx}
                  traveller={traveller}
                />
              )
          })}
        </div>
      </Wrapper>
    </CardContainerStyled>
  )
}

const Wrapper = styled.div`
  .topPortion {
    display: flex;
    margin: 0 auto;
    padding: 1.5rem 3.5rem 1rem 3.5rem;
    justify-content: space-between;
    width: 100%;
  }
`

export default TravellersDisplayCard
