import ACTIONS from "./actionTypes"

export const setEligibility = eligible => ({
  type: ACTIONS.SET_PLAN_ELIGIBILITY,
  payload: eligible,
})

export const setLocation = location => ({
  type: ACTIONS.SET_PLAN_LOCATION,
  payload: location,
})

export const setUserFinalConfirmation = confirm => ({
  type: ACTIONS.SET_USER_FINAL_CONFIRMATION,
  payload: confirm,
})
