import ACTIONS from "../actions/actionTypes"

export const purchaseConfirmation = (state = { currentPolicy: {} }, action) => {
  const { type, payload } = action
  switch (type) {
    case ACTIONS.SET_PLAN_ELIGIBILITY:
      return { ...state, eligible: payload }
    case ACTIONS.SET_PLAN_LOCATION:
      return { ...state, location: payload }
    case ACTIONS.SET_IN_CA_SITUATION:
      return { ...state, ...payload }
    case ACTIONS.SET_CURRENTPOLICY:
      return {
        ...state,
        currentPolicy: { ...state.currentPolicy, ...payload.currentPolicy },
      }
    case ACTIONS.CLEAR_ELIGIBILITY:
      return { ...state, eligible: null }
    case ACTIONS.CLEAR_PLAN_LOCATION:
      return { ...state, location: "" }
    case ACTIONS.CLEAR_IN_CA_SITUATION:
      return { ...state, situation: "" }
    case ACTIONS.CLEAR_CURRENTPOLICY:
      return { ...state, currentPolicy: {} }
    case ACTIONS.SET_USER_FINAL_CONFIRMATION:
      return { ...state, finalConfirmation: payload }
    case ACTIONS.CLEAR_ALL_PURCHASECONFIRMATION:
      return { currentPolicy: {} }
    default:
      return state
  }
}
