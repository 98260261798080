import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"

import LabelStyled from "./LabelStyled"

const RadioWithLabelSimple = ({
  inputFromParent,
  onChangeVal,
  label,
  name,
  options = [],
  radioDisplay,
  radioWidth,
  itemMargin,
  rightMargin,
  required,
  extraDisplayItems,
  wordsMapping,
}) => {
  const onInputChange = e => {
    onChangeVal(wordsMapping[e.target.value])
  }

  const opts = options.map((opt, idx) => (
    <div key={opt} className="radioItem">
      <StyledRadioInput
        type="radio"
        value={opt}
        onChange={onInputChange}
        name={name}
        id={`${name}-${opt}`}
        className="radioButton"
        checked={inputFromParent === wordsMapping[opt]}
      />
      <label
        htmlFor={`${name}-${opt}`}
        css={css`
          color: var(--clr-primary-300);
          display: block;
          line-height: 1.5;
          letter-spacing: var(--letter-spacing-sm);
        `}
      >
        {opt}
      </label>
      {extraDisplayItems && inputFromParent === opt && extraDisplayItems[idx]()}
    </div>
  ))

  return (
    <Wrapper
      display={radioDisplay}
      width={radioWidth}
      itemMargin={itemMargin}
      rightMargin={rightMargin}
    >
      {label && (
        <LabelStyled htmlFor={label}>
          {label}
          {required && "*"}
        </LabelStyled>
      )}
      <div id={name} className="radioWrapper">
        {opts}
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  max-width: 700px;
  margin: 0 auto;
  width: 100%;

  @media (min-width: 500px) {
    width: ${props => props.width || "20rem"};
  }

  .radioWrapper {
    display: ${props => props.display || "flex"};
    margin: 0 auto;
    @media (min-width: 800px) {
      margin-top: 1.5rem;
    }
  }

  .radioItem {
    margin: ${props => props.itemMargin || "0 2rem 0 0"};
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: min-content 1fr;
    justify-content: center;
    width: 100%;

    .extraDisplayItem {
      grid-column: 1/-1;
    }
  }

  .radioButton {
    margin-right: ${props => props.rightMargin || "0.5rem"};
  }
`

const StyledRadioInput = styled.input`
  --active: var(--clr-neutral-100);
  --active-inner: var(--clr-primary-300);
  --focus: 2px rgba(9, 31, 82, 0.2);
  --border: var(--clr-primary-300);
  --border-hover: rgba(9, 31, 82, 0.5);
  --background: #fff;
  appearance: none;
  border-radius: 50%;
  height: 24px;
  width: 24px;
  outline: none;
  display: inline-block;
  vertical-align: top;
  position: relative;
  margin: 0;
  cursor: pointer;
  border: 1px solid var(--bc, var(--border));
  background: var(--b, var(--background));
  transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;

  &:after {
    content: "";
    display: block;
    left: 0;
    top: 0;
    position: absolute;
    transition: transform var(--d-t, 0.3s) var(--d-t-e, ease),
      opacity var(--d-o, 0.2s);
    opacity: var(--o, 0);
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background: var(--active-inner);
    /* opacity: 0; */
    transform: scale(var(--s, 0.7));
  }

  &:checked {
    --b: var(--active);
    --bc: var(--active-inner);
    --d-o: 0.3s;
    --d-t: 0.6s;
    --d-t-e: cubic-bezier(0.2, 0.85, 0.32, 1.2);
    --o: 1;
    --s: 0.5;
  }

  &:hover {
    &:not(:checked) {
      &:not(:disabled) {
        --bc: var(--border-hover);
      }
    }
  }

  &:focus {
    box-shadow: 0 0 0 var(--focus);
  }

  & + label {
    font-size: 1rem;
    line-height: 21px;
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
    margin-left: 4px;
  }
`

export default RadioWithLabelSimple
