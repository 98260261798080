import React from "react"
import styled from "@emotion/styled"

const InputDisplay = ({ label, content }) => {
  return (
    <Wrapper>
      <p>{label}</p>
      <div>
        <p>{content}</p>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  max-width: 15rem;
  width: 100%;
  margin: 0 auto;
  p {
    margin-bottom: 0.75rem;
  }
  div {
    border: 2px solid var(--clr-primary-300);
    border-radius: var(--border-radius-md);
    max-width: ${props => props.maxWidth || "15rem"};
    width: ${props => props.width || "100%"};
    p {
      margin: 0;
      padding: 0.75rem 0 0.75rem 1.5rem;
      font-weight: bold;
    }
  }
`

export default InputDisplay
