import React from "react"
import { Global, css } from "@emotion/core"
import { Provider } from "react-redux"
import { initStore } from "./src/redux/store"
import { mq } from "./src/utils/media-query"
import { AppWrapper } from "./src/components"

import "react-dates/initialize"
import "react-dates/lib/css/_datepicker.css"
import "./src/css/react_dates_overrides.css"

export const wrapRootElement = ({ element }) => {
  return (
    <>
      <Global
        styles={css`
          /*
          =============== 
          Variables
          ===============
          */

          :root {
            /* primary colors and its shades */
            --clr-primary-100: #1098a2;
            /* rgb(16, 152, 162) */
            --clr-primary-100hover: #12acb7;
            --clr-primary-100active: #13becb;
            --clr-primary-200: #5a993e;
            --clr-primary-300: #091f52;
            /* rgb(9, 31, 82) */
            --clr-primary-400: #e86267;
            --clr-primary-500: #f88353;
            --clr-neutral-100: #ffffff;
            --clr-neutral-200: #f0f0f0;
            --clr-neutral-200lighter: #f8f8f8;
            --clr-neutral-300: #bbbbbb;
            --clr-neutral-400: #949494;
            --clr-neutral-500: #484848;

            /* font family */
            --ff-primary: "Paytone one", sans-serif;
            --ff-secondary: "Poly", sans-serif;
            --ff-tertiary: "Noto Sans SC", sans-serif;
            --ff-quaternary: "Poppins", sans-serif;

            /* font size */
            --fs-heading-1: 2rem;

            /* animation */
            --transition: all 0.3s linear;

            /* spacing */
            --letter-spacing-sm: 0.05rem;
            --letter-spacing-md: 0.1rem;
            --letter-spacing-lg: 0.2rem;

            /* width / height */
            --max-width: 1170px;
            --fixed-width: 700px;

            /* styles */
            --border-radius: 0.25rem;
            --border-radius-md: 0.5rem;
            --border-radius-lg: 1rem;
            --light-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
            --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
            --dark-shadow-sb: 0 0px 6px rgba(0, 0, 0, 0.2);
            --solid-shadow-primary300: 0.2rem 0.2rem 0 0 rgba(9, 31, 82, 1);
            --solid-shadow-primary400: 0.2rem 0.2rem 0 0 rgba(232, 98, 103, 1);
            --solid-shadow-primary100: 0.2rem 0.2rem 0 0 rgba(16, 152, 162, 1);
            --dropdown-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.2);
          }

          /*
          =============== 
          Global Styles
          ===============
          */

          *,
          ::after,
          ::before {
            margin: 0;
            padding: 0;
            box-sizing: border-box;
          }

          html {
            height: 100%;
          }

          body {
            font-family: var(--ff-tertiary);
            background: var(--clr-neutral-200lighter);
            color: var(--clr-primary-300);
            line-height: 1.5;
            font-size: 0.875rem;
            /* margin-top: 5rem; */
            min-height: 100%;
          }
          ul {
            list-style-type: none;
          }
          a {
            text-decoration: none;
          }

          h1,
          h2,
          h3,
          h4 {
            letter-spacing: var(--letter-spacing-sm);
            text-transform: capitalize;
            line-height: 1.25;
            margin-bottom: 0.75rem;
            font-family: var(--ff-primary);
          }
          h1 {
            font-size: 2.5rem;
            color: var(--clr-primary-300);
          }
          h2 {
            font-size: 2rem;
            color: var(--clr-neutral-500);
          }
          h3 {
            font-size: 1.25rem;
          }
          h4 {
            font-size: 0.875rem;
          }
          p {
            margin-bottom: 1.25rem;
            color: var(--clr-primary-300);
          }

          ::-webkit-scrollbar {
            width: 12px;
          }
          ::-webkit-scrollbar-track {
            border-radius: var(--border-radius);
            background: var(--clr-neutral-200);
          }
          ::-webkit-scrollbar-thumb {
            border-radius: var(--border-radius);
            background: var(--clr-neutral-300);

            :hover {
              background: var(--clr-neutral-400);
            }
          }

          ${mq[0]} {
            h1 {
              line-height: 1.5;
            }
            /* h2 {
              font-size: 2rem;
            } */
            h3 {
              font-size: 1.75rem;
            }
            h4 {
              font-size: 1rem;
            }
            body {
              font-size: 1rem;
            }

            h2,
            h3,
            h4 {
              line-height: 1;
            }
          }
        `}
      />
      <Provider store={initStore()}>
        <AppWrapper>{element}</AppWrapper>
      </Provider>
    </>
  )
}
