import styled from "@emotion/styled"

const Button = styled.button`
  padding: 0.375rem 1rem;
  transition: var(--transition);
  border-radius: var(--border-radius);
  cursor: pointer;
  letter-spacing: var(--letter-spacing-sm);
  border: none;
  font-family: var(--ff-tertiary);
`

const PrimaryButton = styled(Button)`
  padding: ${props => props.padding || "1rem 1.5rem"};
  text-transform: ${props => props.textTransform || "uppercase"};
  font-weight: 700;
  color: var(--clr-neutral-100);
  border: 2px solid var(--clr-primary-100);
  font-size: ${props => props.textSize || "1rem"};
  margin: ${props => props.margin || "0 auto"};
  display: ${props => props.display || "block"};
  background-color: ${props =>
    props.backgroundColor || "var(--clr-primary-100)"};
  min-width: ${props => props.maxWidth || "190px"};
  :disabled {
    border: 2px solid var(--clr-neutral-300);
    background-color: var(--clr-neutral-300);
    cursor: not-allowed;
  }
  width: ${props => props.width || "none"};
`

const SecondaryButton = styled(Button)`
  padding: ${props => props.padding || "1rem 1.5rem"};
  text-transform: ${props => props.textTransform || "capitalize"};
  background: none;
  border: ${props => props.borderLine || "2px"} solid
    ${props => props.buttonColor};
  color: ${props => props.buttonColor};
  font-size: ${props => props.textSize || "inherit"};
  font-weight: ${props => props.textWeight || "400"};
  margin: ${props => props.margin || "0 auto"};
  display: ${props => props.display || "block"};
  :disabled {
    border: ${props => props.disabledBorder || "1px"} solid
      var(--clr-neutral-300);
    color: var(--clr-neutral-300);
    cursor: not-allowed;
  }
`

export { PrimaryButton, SecondaryButton }
