import ACTIONS from "./actionTypes"

export const insurerAvailAction = payload => ({
  type: ACTIONS.SET_INSURE_AVAIL,
  payload,
})

export const preExistingConditionAction = payload => ({
  type: ACTIONS.SET_PRE_EXISTING,
  payload,
})

export const famRateAction = payload => ({
  type: ACTIONS.SET_IS_FAM_RATE,
  payload,
})
