import React from "react"

import Links from "./Links"
import { mq } from "../utils/media-query"
import links from "../utils/links"

const navLinkItems = links.filter(link => link.page !== "get-quote-details")

const linkStyle = {
  [mq[0]]: {
    marginRight: "1.5rem",
    color: "var(--clr-primary-300)",
    letterSpacing: "var(--letter-spacing-sm)",
    textTransform: "capitalize",
    fontWeight: "bold",
    fontSize: "0.875rem",
    transition: "var(--transition)",
    cursor: "pointer",
    // padding: "0.25rem 0.5rem",
    /* 'border-radius': 'var(--border-radius)', */

    "&:hover": {
      /* 'background-color': 'var(--clr-primary-100)', */
      color: "var(--clr-primary-100)",
    },
    "&.current-page": {
      color: "var(--clr-primary-100)",
      boxShadow: "0 0.2rem 0 0 var(--clr-primary-100)",
      transition: "var(--transition)",
    },
  },
}

const ulStyle = {
  display: "none",
  [mq[0]]: {
    display: "flex",
    alignItems: "center",
    marginLeft: "1rem",
  },
}

const NavLinks = () => {
  return <Links ulStyle={ulStyle} linkStyle={linkStyle} links={navLinkItems} />
}

export default NavLinks
