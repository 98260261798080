import React from "react"
import { css } from "@emotion/core"
import { useIntl } from "react-intl"

import Title from "./Title"
import Slider from "./Slider"

const CustomerReviews = () => {
  return (
    <section
      css={css`
        padding: 5rem 0;
        background: var(--clr-neutral-200lighter);
        text-align: center;
      `}
    >
      <Title title={useIntl().formatMessage({ id: "home.reviewcap" })} />
      <Slider />
    </section>
  )
}

export default CustomerReviews
