import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { useIntl } from "react-intl"

import { LabelStyled, InputDisplay } from "."

const TravellerDisplay = ({ role, traveller }) => {
  const intl = useIntl()
  const displayItems = {
    first_name: intl.formatMessage({ id: "travellers.holderfname" }),
    last_name: intl.formatMessage({ id: "travellers.holderlname" }),
    birth_date: intl.formatMessage({ id: "travellers.holderdob" }),
    gender: intl.formatMessage({ id: "travellers.holdergender" }),
  }

  return (
    <Wrapper>
      <div className="innerWrap">
        <div className="main">
          <LabelStyled
            css={css`
              margin: 0 auto;
              justify-self: start;
              grid-column: 1/-1;
            `}
          >
            {role}
          </LabelStyled>
          {Object.keys(displayItems).map((key, idx) => {
            if (traveller[key])
              return (
                <InputDisplay
                  label={displayItems[key]}
                  key={idx}
                  content={traveller[key]}
                />
              )
          })}
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  margin: 0 auto;

  .innerWrap {
    border-radius: var(--border-radius);
    border: 1px solid var(--clr-primary-300);
    margin: 0 2rem 2rem 2rem;
  }

  .main {
    width: 100%;
    margin: 0 auto;
    padding: 2rem 1.5rem 3rem 1.5rem;
    grid-template-columns: 1fr;
    display: grid;
    justify-items: center;
    column-gap: 2rem;
    row-gap: 1.5rem;

    @media (min-width: 500px) {
      grid-template-columns: 1fr 1fr;
    }
  }
`

export default TravellerDisplay
