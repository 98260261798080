import { combineReducers } from "redux"

import { currentPath } from "./pathReducer"
import { purposeOfTravel } from "./purposeReducer"
import { travelPlan } from "./travelPlanReducer"
import { travellers } from "./travellersReducer"
import { canProceedFromUI } from "./proceedFromUIReducer"
import {
  coverage,
  deductible,
  unavailableCoverages,
  unavailableDeductibles,
  allCoveragesDeductibles,
} from "./coverageDeductibleReducer"
import {
  apiCallStatus,
  apiHealth,
  tokenExpiryTime,
  conditions,
  quotes,
  allQuotesLoaded,
  purchaseResult,
  searchPolicyResult,
  contactEmail,
} from "./apiReducers"
import { insurerAvail, preExisting, famRate } from "./plansReducers"
import { compareInsurer } from "./compareInsurerReducer"
import { selectedPlan } from "./selectedPlanReducers"
import { purchaseConfirmation } from "./purchaseFlowReducer"
import { locale } from "./localeReducer"
import { options } from "./optionsReducer"

export default combineReducers({
  currentPath,
  purposeOfTravel,
  travelPlan,
  travellers,
  canProceedFromUI,
  apiCallStatus,
  apiHealth,
  conditions,
  quotes,
  tokenExpiryTime,
  coverage,
  unavailableCoverages,
  deductible,
  unavailableDeductibles,
  allCoveragesDeductibles,
  allQuotesLoaded,
  compareInsurer,
  selectedPlan,
  purchaseConfirmation,
  purchaseResult,
  searchPolicyResult,
  insurerAvail,
  preExisting,
  locale,
  options,
  famRate,
  contactEmail,
})
