export default [
  {
    page: "get-quote-details",
    label: "get a quote",
    id: "home.btn1",
    url: `/get-quote-details`,
    icon: null,
  },
  {
    page: "about",
    label: "about us",
    id: "nav.link1",
    url: `/about`,
    icon: null,
  },
  {
    page: "contact",
    label: "contact us",
    id: "nav.link2",
    url: `/contact`,
    icon: null,
  },
  {
    page: "in-the-news",
    label: "in the news",
    id: "nav.link3",
    url: `/in-the-news`,
    icon: null,
  },
]
