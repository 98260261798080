export const preExistingCoverage = {
  destination: { code: "include-pre-existing-coverage" },
  gms: null,
  tugo: null,
}

export const availabilityFor80yrsOldOrAbove = {
  destination: false,
  gms: false,
  tugo: true,
}

export const sportsCoverageOptions = {
  None: { code: "none" },
  "Backcountry Skiing/Snowboarding": {
    code: "PM-SPORT-1",
    "21above": false,
  },
  Boxing: { code: "PM-SPORT-2", "21above": false },
  "Downhill Freestyle Skiing/Snowboarding in Organized Competitions": {
    code: "PM-SPORT-3",
    "21above": false,
  },
  "Downhill Mountain Biking": { code: "PM-SPORT-4", "21above": false },
  "Ice Climbing": { code: "PM-SPORT-5", "21above": false },
  "Mixed Martial Arts": { code: "PM-SPORT-6", "21above": false },
  Mountaineering: { code: "PM-SPORT-7", "21above": false },
  "Rock Climbing": { code: "PM-SPORT-8", "21above": false },
  "Football (American and Canadian)": {
    code: "PM-SPORT-9",
    "21above": true,
  },
  "Ice Hockey": {
    code: "PM-SPORT-10",
    "21above": true,
  },
  Rugby: {
    code: "PM-SPORT-11",
    "21above": true,
  },
  "BASE Jumping": { code: "PM-VSPT-12", "21above": false },
  "Hang Gliding/Paragliding": { code: "PM-VSPT-13", "21above": false },
  "High Risk Snowmobiling": { code: "PM-VSPT-14", "21above": false },
  "Motorized Speed Contests": { code: "PM-VSPT-15", "21above": false },
  "Parachuting/Skydiving/Tandem Skydiving": {
    code: "PM-VSPT-16",
    "21above": false,
  },
  "Scuba Diving or Free Diving Over 40 Metres": {
    code: "PM-VSPT-17",
    "21above": false,
  },
  "White Water Sports – Class VI": {
    code: "PM-VSPT-18",
    "21above": false,
  },
  "Wingsuit Flying": { code: "PM-VSPT-19", "21above": false },
}
