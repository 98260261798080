import React from "react"
import { css } from "@emotion/core"
import { GoPlus } from "react-icons/go"
import { useIntl } from "react-intl"

import { CardContainerStyled } from "."
import { mq } from "../utils/media-query"

const AddPerson = ({ onClick }) => {
  const intl = useIntl()
  return (
    <CardContainerStyled
      onClick={onClick}
      css={css`
        transition: var(--transition);
        :hover {
          border: 2px solid var(--clr-primary-100);
          box-shadow: var(--solid-shadow-primary100);
          cursor: pointer;

          .plus-icon,
          .button-text {
            color: var(--clr-primary-100);
          }
        }

        @media (min-width: 800px) {
          width: 100%;
          margin: 0 auto;
        }
        ${mq[1]} {
          width: 90%;
        }
      `}
    >
      <div
        css={css`
          padding: 2rem;
          display: flex;
          width: 90%;
          margin: 0 auto;
          justify-content: center;

          @media (min-width: 800px) {
            align-items: flex-start;
            column-gap: 2rem;
            justify-items: center;
            width: 90%;
          }
        `}
      >
        <GoPlus
          css={css`
            font-size: 2rem;
          `}
          className="plus-icon"
        />
        <p
          css={css`
            font-size: 1.25rem;
            margin: 0;
            padding: 0;
          `}
          className="button-text"
        >
          {intl.formatMessage({ id: "traveller.add" })}
        </p>
      </div>
    </CardContainerStyled>
  )
}

export default AddPerson
