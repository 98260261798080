import React from "react"
import { css } from "@emotion/core"

import Footer from "./Footer"
import NavBar from "./NavBar"

const LayoutQuoting = ({ children }) => {
  return (
    <>
      <NavBar quoting bkgColor={"var(--clr-neutral-100)"} />
      <main
        css={css`
          background-color: var(--clr-neutral-100);
        `}
      >
        {children}
      </main>
      <Footer />
    </>
  )
}

export default LayoutQuoting
