import ACTIONS from "./actionTypes"
import * as api from "../../api/api"

export const setAPIError = (err, endpoint) => ({
  type: ACTIONS.API_CALL_ERROR,
  err,
  endpoint,
})

export const beginAPICall = endpoint => ({
  type: ACTIONS.BEGIN_API_CALL,
  endpoint,
})
export const APICallEnded = endpoint => ({
  type: ACTIONS.API_CALL_END,
  endpoint,
})

export const getHealth = () => async dispatch => {
  const endpoint = "/health"
  dispatch(beginAPICall(endpoint))
  try {
    const res = await api.getHealth()

    dispatch({
      type: ACTIONS.GET_API_HEALTH,
      payload: res.data.status,
    })
    dispatch(APICallEnded(endpoint))
  } catch (err) {
    dispatch(setAPIError(err, endpoint))
  }
}

export const setToken = () => async (dispatch, getState) => {
  let localStorage = typeof window !== "undefined" && window.localStorage
  let token = localStorage.getItem("token")
  const tokenExpiryTime = localStorage.getItem("tokenExpiryTime")
  const apiHealth = getState().apiHealth

  if (
    apiHealth === "OK" &&
    (!token ||
      !tokenExpiryTime ||
      Math.floor(Date.now() / 1000) > tokenExpiryTime)
  ) {
    try {
      let callTime = Math.floor(Date.now() / 1000)
      let res = await api.getToken()
      const { access_token, expires_in } = res.data
      process.env.IS_DEV &&
        console.log(
          `token endpoint called at ${callTime}, the new token expires at ${expires_in}`
        )

      localStorage.setItem("token", access_token)
      localStorage.setItem("tokenExpiryTime", callTime + expires_in)
      dispatch({
        type: ACTIONS.SET_TOKEN_EXPIRY_TIME,
        payload: callTime + expires_in,
      })

      token = access_token
    } catch (err) {
      throw err
    }
  }
}

// no longer calling getCountries api
// export const getCountries = () => async dispatch => {
//   const endpoint = "/countries"
//   dispatch(setToken())
//   dispatch(beginAPICall(endpoint))
//   try {
//     const res = await api.getCountries()
//     dispatch({
//       type: ACTIONS.GET_COUNTRIES,
//       payload: res.data.sort(a => (a.name.toLowerCase() === "china" ? -1 : 1)),
//     })
//     dispatch(APICallEnded(endpoint))
//   } catch (err) {
//     dispatch(setAPIError(err, endpoint))
//   }
// }

export const getConditions = conditionsPayload => async dispatch => {
  const endpoint = "/conditions"
  dispatch(setToken())
  dispatch(beginAPICall(endpoint))
  try {
    const res = await api.getConditions(conditionsPayload)
    dispatch({
      type: ACTIONS.GET_CONDITIONS,
      payload: res.data,
    })
    dispatch(APICallEnded(endpoint))
  } catch (err) {
    dispatch(setAPIError(err, endpoint))
  }
}

export const getQuoteGMS = quotePayload => async dispatch => {
  const endpoint = "/quote/gms"
  dispatch(setToken())
  dispatch(beginAPICall(endpoint))
  try {
    const res = await api.getQuoteGMS(quotePayload)
    dispatch({
      type: ACTIONS.GET_QUOTE_GMS,
      payload: res.data,
    })
    dispatch(APICallEnded(endpoint))
  } catch (err) {
    dispatch({
      type: ACTIONS.GET_QUOTE_GMS,
      payload: {
        error: err.response && err.response.data && err.response.data.error,
        price: null,
      },
    })
    dispatch(setAPIError(err, endpoint))
  }
}
export const getQuoteTugo = quotePayload => async dispatch => {
  const endpoint = "/quote/tugo"
  dispatch(setToken())
  dispatch(beginAPICall(endpoint))
  try {
    const res = await api.getQuoteTugo(quotePayload)
    dispatch({
      type: ACTIONS.GET_QUOTE_TUGO,
      payload: res.data,
    })
    dispatch(APICallEnded(endpoint))
  } catch (err) {
    dispatch({
      type: ACTIONS.GET_QUOTE_TUGO,
      payload: {
        error: err.response && err.response.data && err.response.data.error,
        price: null,
      },
    })
    dispatch(setAPIError(err, endpoint))
  }
}
export const getQuoteDestin = quotePayload => async dispatch => {
  const endpoint = "/quote/destination"
  dispatch(setToken())
  dispatch(beginAPICall(endpoint))
  try {
    const res = await api.getQuoteDestin(quotePayload)
    dispatch({
      type: ACTIONS.GET_QUOTE_DESTIN,
      payload: res.data,
    })
    dispatch(APICallEnded(endpoint))
  } catch (err) {
    dispatch({
      type: ACTIONS.GET_QUOTE_DESTIN,
      payload: {
        error: err.response && err.response.data && err.response.data.error,
        price: null,
      },
    })
    dispatch(setAPIError(err, endpoint))
  }
}

export const quoteActionsToDispatch = {
  gms: getQuoteGMS,
  tugo: getQuoteTugo,
  destination: getQuoteDestin,
}

export const clearQuotes = () => ({
  type: ACTIONS.CLEAR_QUOTES,
})

export const setQuotesLoaded = loaded => ({
  type: ACTIONS.SET_QUOTES_LOADED,
  payload: loaded,
})

export const setPayment = (purchasePayload, insurer) => async dispatch => {
  const endpoint = `/purchase/${insurer.toLowerCase()}`
  dispatch(setToken())
  dispatch(beginAPICall(endpoint))
  try {
    const res = await api.purchaseInsurerPlan(purchasePayload, insurer)
    dispatch({
      type: ACTIONS.MAKE_PURCHASE,
      payload: res.data,
      insurer,
    })
    dispatch(APICallEnded(endpoint))
  } catch (err) {
    dispatch(setAPIError(err, endpoint))
    dispatch({
      type: ACTIONS.MAKE_PURCHASE,
      payload: err.response,
      insurer,
    })
  }
}

export const sendContactEmail = payload => async dispatch => {
  const endpoint = `contact`
  dispatch(setToken())
  dispatch(beginAPICall(endpoint))
  try {
    const res = await api.sendContact(payload)
    dispatch({
      type: ACTIONS.SEND_CONTACT,
      payload: res.data,
    })
    dispatch(APICallEnded(endpoint))
  } catch (err) {
    dispatch(setAPIError(err, endpoint))
    dispatch({
      type: ACTIONS.SEND_CONTACT,
      payload: err.response || "error occurred",
    })
  }
}

export const searchPolicy = (type, term) => async dispatch => {
  const endpoint = `/search?${type}=${term}`
  dispatch(setToken())
  dispatch(beginAPICall(endpoint))
  try {
    const res = await api.getPolicy(type, term)
    dispatch({
      type: ACTIONS.GET_POLICY,
      payload: res.data,
    })
    dispatch(APICallEnded(endpoint))
  } catch (err) {
    dispatch(setAPIError(err, endpoint))
    dispatch({
      type: ACTIONS.GET_POLICY,
      payload: err.response || "not found",
    })
  }
}
