import React from "react"
import Img from "gatsby-image"
import styled from "@emotion/styled"

import { mq } from "../utils/media-query"

const InTheNewsCard = ({ img, cap, link, date, reverse }) => {
  return (
    <Wrapper href={link} target="_blank" reverse={reverse}>
      {reverse ? (
        <>
          <div className={`innerWrapper`}>
            <div className="date">{date}</div>
            <div className="cap">{cap}</div>
            <div className="img">
              <Img
                fluid={img.fluid}
                imgStyle={{
                  objectPosition: "center top",
                  objectFit: "cover",
                }}
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={`innerWrapper`}>
            <div className="img">
              <Img
                fluid={img.fluid}
                imgStyle={{
                  objectPosition: "center top",
                  objectFit: "cover",
                }}
              />
            </div>
            <div className="cap">{cap}</div>
            <div className="date">{date}</div>
          </div>
        </>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.a`
  background: none;
  text-decoration: none;
  display: block;
  border-radius: var(--border-radius);
  box-shadow: var(--dark-shadow-sb);
  margin: 0 auto;
  width: 90%;
  padding: 2rem 1rem;
  height: 400px;
  cursor: pointer;
  justify-items: center;

  :visited {
    color: inherit;
  }
  :hover {
    box-shadow: none;
    border: 2px solid var(--clr-primary-300);
  }

  .innerWrapper {
    display: grid;
    align-items: center;
    row-gap: 2rem;
    height: 100%;

    .img {
      max-width: 250px;
      width: 100%;
      margin: 0 auto;
    }

    .cap {
      font-size: 1rem;
      padding: 0 1rem;
      font-weight: bold;
      margin: 0 auto;

      @media (min-width: 600px) {
        margin: 0;
      }
    }

    .date {
      font-family: var(--ff-quaternary);
      font-size: 0.875rem;
      margin: 0 auto;
      padding: 0 1rem;
      @media (min-width: 600px) {
        margin: 0;
      }
    }
  }
`

export default InTheNewsCard
