import React from "react"
import { useDispatch, useSelector } from "react-redux"
import styled from "@emotion/styled"
import { css } from "@emotion/core"

import ACTIONS from "../redux/actions/actionTypes"

const LanguageSwitcher = () => {
  const dispatch = useDispatch()
  const locale = useSelector(state => state.locale)
  const setLang = lang => {
    dispatch({
      type: ACTIONS.SET_LOCALE,
      payload: lang,
    })
  }
  return (
    <>
      <LangChoice onClick={() => setLang("en")} selected={locale === "en"}>
        🇨🇦
      </LangChoice>
      <span
        css={css`
          font-size: 1.5rem;
        `}
      >
        |
      </span>
      <LangChoice onClick={() => setLang("zh")} selected={locale === "zh"}>
        🇨🇳
      </LangChoice>
    </>
  )
}

const LangChoice = styled.div`
  padding: 0 0.5rem;
  border-radius: 15%;
  color: var(--clr-primary-300);
  cursor: pointer;
  background-color: ${props =>
    props.selected ? "rgba(9, 31, 82, 0.3)" : "none"};
  font-size: 1.5rem;
`

export default LanguageSwitcher
