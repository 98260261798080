import React from "react"
import { navigate } from "gatsby"
import { css } from "@emotion/core"
import { useIntl } from "react-intl"

import Title from "./Title"
import { PrimaryButton } from "./buttons"

const ReachOutToUs = () => {
  const intl = useIntl()
  const onContactUs = () => {
    navigate("/contact")
  }
  return (
    <section
      css={css`
        padding: 5rem 0;
        background: var(--clr-neutral-200);
        text-align: center;
      `}
    >
      <Title title={intl.formatMessage({ id: "home.reachoutcap" })} />
      <p
        css={css`
          margin-top: -2rem;
          margin-bottom: 2rem;
          font-family: var(--ff-quaternary);
          font-size: 1rem;
          color: var(--clr-primary-300);
        `}
      >
        {intl.formatMessage({ id: "home.reachout" })}
      </p>
      <PrimaryButton
        css={css`
          margin: 0 auto;
        `}
        onClick={onContactUs}
      >
        {intl.formatMessage({ id: "home.reachoutbtn" })}
      </PrimaryButton>
    </section>
  )
}

export default ReachOutToUs
