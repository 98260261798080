import ACTIONS from "../actions/actionTypes"
import insurers from "../../utils/insurers"

const initCompareInsurers = insurers.reduce((acc, i) => {
  acc[i] = false
  return acc
}, {})

export const compareInsurer = (state = initCompareInsurers, action) => {
  const { type, payload } = action
  if (type === ACTIONS.SET_COMPARE_INSURER) {
    return { ...state, ...payload }
  }
  if (type === ACTIONS.CLEAR_COMPARE_INSURER) {
    return initCompareInsurers
  }
  return state
}
