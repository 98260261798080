import React, { useState, useEffect, useRef } from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { FaAngleDown, FaAngleUp } from "react-icons/fa"
import { AiOutlineLoading3Quarters } from "react-icons/ai"
import { useIntl } from "react-intl"

import { mq } from "../utils/media-query"
import LabelStyled from "./LabelStyled"

const DropdownSimple = ({
  onChangeOpt,
  label,
  dataArr,
  invalid,
  margin,
  initPH,
}) => {
  const intl = useIntl()
  const [active, setActive] = useState(false)
  const [filter, setFilter] = useState("")
  const [selected, setSelected] = useState(initPH)
  const refContainer = useRef(null)

  useEffect(() => {
    const handleClickOutside = e => {
      if (refContainer.current && !refContainer.current.contains(e.target)) {
        setFilter("")
        setActive(false)
      }
    }
    document.addEventListener("click", handleClickOutside)
    return () => {
      document.removeEventListener("click", handleClickOutside)
    }
  }, [refContainer])

  const options = dataArr
    .filter(item => item.toLowerCase().startsWith(filter.toLowerCase()))
    .map((item, idx) => (
      <React.Fragment key={item}>
        <li data-val={item}>{intl.formatMessage({ id: item })}</li>
        {idx !== dataArr.length - 1 && <div className="divider"></div>}
      </React.Fragment>
    ))

  const onSelect = e => {
    console.log(e.target.value)
    setSelected(e.target.innerHTML)
    setFilter("")
    setActive(false)
    onChangeOpt(e.target.dataset.val)
  }

  const onInputChange = e => {
    setActive(true)
    setFilter(e.target.value)
  }

  return (
    <Wrapper invalid={invalid ? 1 : undefined} margin={margin}>
      <LabelStyled htmlFor={label}>{label}</LabelStyled>
      <div className="select-wrap" id={label} ref={refContainer}>
        {!active ? (
          <FaAngleDown
            css={css`
              position: absolute;
              color: ${invalid
                ? "var(--clr-primary-400)"
                : "var(--clr-primary-300)"};
              top: 1.4rem;
              left: 18rem;
              font-size: 1rem;
              pointer-events: none;
            `}
          />
        ) : dataArr.length <= 0 ? (
          <AiOutlineLoading3Quarters
            className="spin"
            css={css`
              position: absolute;
              top: 1.4rem;
              left: 18rem;
              font-size: 1rem;
              pointer-events: none;
              color: ${invalid
                ? "var(--clr-primary-400)"
                : "var(--clr-primary-300)"};
            `}
          />
        ) : (
          <FaAngleUp
            css={css`
              position: absolute;
              top: 1.4rem;
              left: 18rem;
              font-size: 1rem;
              pointer-events: none;
              color: ${active
                ? "var(--clr-primary-100)"
                : "var(--clr-primary-300)"};
            `}
          />
        )}
        <ul
          className={`default-option ${active && "active-default"}`}
          onClick={() => setActive(!active)}
        >
          <input
            type="text"
            value={filter}
            onChange={onInputChange}
            placeholder={selected}
            className={`${active && "active-input"}`}
          />
        </ul>
        <ul className={`select-ul ${active && "active"}`} onClick={onSelect}>
          {options}
        </ul>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 20rem;
  margin: ${props => props.margin || "0 auto"};
  ${mq[0]} {
  }

  .select-wrap {
    width: 20rem;
    position: relative;
    user-select: none;
  }

  .default-option {
    border-radius: var(--border-radius-md);
    border: 2px solid
      ${props =>
        props.invalid ? "var(--clr-primary-400)" : "var(--clr-primary-300)"};
    max-width: 20rem;
    cursor: pointer;
  }

  .select-ul {
    position: absolute;
    top: 4rem;
    left: 0;
    border-radius: var(--border-radius-md);
    box-shadow: var(--dropdown-shadow);
    width: 100%;
    background: var(--clr-neutral-100);
    display: none;
    max-height: 10.5rem;
    overflow-y: auto;
  }
  .select-ul li {
    cursor: pointer;
    :hover {
      background: rgba(16, 152, 162, 0.2);
    }

    :first-of-type:hover {
      border-top-left-radius: var(--border-radius-md);
      /* border-top-right-radius: var(--border-radius-md); */
    }
    :last-of-type:hover {
      border-bottom-left-radius: var(--border-radius-md);
      /* border-bottom-right-radius: var(--border-radius-md); */
    }
  }

  li {
    padding: 1rem;
    text-overflow: ellipsis;
  }

  .divider {
    width: 90%;
    height: 1px;
    background-color: var(--clr-neutral-200);
    margin: 0 auto;
  }

  .active {
    display: block;
    z-index: 999;
  }

  .active-default {
    border: 2px solid var(--clr-primary-100);
    color: var(--clr-primary-100);
  }

  input {
    padding: 1rem;
    border-radius: var(--border-radius-md);
    border: none;
    outline: none;
    font-family: var(--ff-tertiary);
    font-size: 1rem;
    text-transform: capitalize;
    color: var(--clr-primary-300);
    /* cursor: pointer; */
    width: 90%;

    ::placeholder {
      color: ${props =>
        props.invalid ? "var(--clr-primary-400)" : "var(--clr-primary-300)"};
    }
  }

  .active-input,
  .active-input::placeholder {
    color: var(--clr-primary-100);
  }

  .spin {
    animation: spin 0.8s infinite linear;
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(359deg);
    }
  }
`

export default DropdownSimple
