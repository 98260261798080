import React, { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { SingleDatePicker } from "react-dates"
import { css } from "@emotion/core"

import LabelStyled from "./LabelStyled"

const DateDropdown = ({
  secondLevelStatePropToChange,
  firstLevelStatePropToChange,
  thirdLevelStatePropToChange,
  actionType,
  label,
  orientation,
  readOnly,
  labelFontSize,
  ...props
}) => {
  const dispatch = useDispatch()
  const date = useSelector(state =>
    thirdLevelStatePropToChange
      ? state[firstLevelStatePropToChange][secondLevelStatePropToChange][
          thirdLevelStatePropToChange
        ]
      : state[firstLevelStatePropToChange][secondLevelStatePropToChange]
  )

  const [focus, setFocus] = useState(false)

  const matchMediaStr = "(min-width: 768px)"
  const [datePickerOrient, setDatePickerOrient] = useState(
    typeof window === "undefined"
      ? null
      : window.matchMedia(matchMediaStr).matches
      ? "horizontal"
      : "vertical"
  )

  useEffect(() => {
    if (typeof window === "undefined") {
      return
    }

    const handleResize = () => {
      setDatePickerOrient(
        window.matchMedia(matchMediaStr).matches ? "horizontal" : "vertical"
      )
    }
    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  })

  const onDateChange = date => {
    dispatch({
      type: actionType,
      payload: thirdLevelStatePropToChange
        ? {
            [secondLevelStatePropToChange]: {
              [thirdLevelStatePropToChange]: date,
            },
          }
        : {
            [secondLevelStatePropToChange]: date,
          },
    })
  }

  const onFocusChange = ({ focused }) => {
    setFocus(focused)
  }

  return (
    <div
      css={css`
        margin: 0 auto;
        width: 20rem;
      `}
    >
      <LabelStyled
        htmlFor={
          thirdLevelStatePropToChange
            ? thirdLevelStatePropToChange
            : secondLevelStatePropToChange
        }
        fontSize={labelFontSize}
      >
        {label}
      </LabelStyled>
      <SingleDatePicker
        {...props}
        date={date}
        id={
          thirdLevelStatePropToChange
            ? thirdLevelStatePropToChange
            : secondLevelStatePropToChange
        }
        focused={focus}
        onDateChange={onDateChange}
        onFocusChange={onFocusChange}
        orientation={datePickerOrient}
        placeholder="YYYY-MM-DD"
        hideKeyboardShortcutsPanel
        readOnly={readOnly}
        showDefaultInputIcon
        displayFormat="YYYY-MM-DD"
        transitionDuration={0}
      />
    </div>
  )
}

export default DateDropdown
