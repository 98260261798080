import { graphql, useStaticQuery } from "gatsby"

const useInsurersLogos = () => {
  const query = useStaticQuery(graphql`
    query {
      logo1: file(relativePath: { regex: "/gms-logo.png/" }) {
        gms: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      logo2: file(relativePath: { regex: "/tugo-logo.png/" }) {
        tugo: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      logo3: file(relativePath: { regex: "/destin-logo.png/" }) {
        destination: childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const {
    logo1: { gms },
    logo2: { tugo },
    logo3: { destination },
  } = query

  return { gms, tugo, destination }
}

export default useInsurersLogos
