import ACTIONS from "./actionTypes"

export const setSelectedPlan = plan => ({
  type: ACTIONS.SET_SELECTED_PLAN,
  payload: plan,
})

export const clearSelectedPlan = () => ({
  type: ACTIONS.SET_SELECTED_PLAN,
})
