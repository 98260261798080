import React from "react"
import { css } from "@emotion/core"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { useIntl } from "react-intl"

import Title from "./Title"
import { mq } from "../utils/media-query"

const TrustingPartners = () => {
  const query = useStaticQuery(graphql`
    query {
      images: allFile(filter: { relativePath: { regex: "/-logo.png/" } }) {
        logos: nodes {
          logo: childImageSharp {
            fluid(duotone: { shadow: "#222222", highlight: "#f8f8f8" }) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
  `)
  const {
    images: { logos },
  } = query

  return (
    <section
      css={css`
        padding: 4rem 0 3rem 0;
        background: var(--clr-neutral-200lighter);
      `}
    >
      <Title
        title={useIntl().formatMessage({ id: "home.partner" })}
        titleFont="var(--ff-secondary)"
      />
      <div
        css={css`
          display: grid;
          grid-template-rows: repeat(3, 33%);
          width: 90vw;
          margin: 0 auto;
          max-width: 1170px;

          ${mq[0]} {
            width: 95vw;
            grid-template-rows: 1fr;
            grid-template-columns: 1fr 1fr 1fr;
            -webkit-column-gap: 2rem;
            -moz-column-gap: 2rem;
            column-gap: 2rem;
          }
        `}
      >
        {logos.map((item, idx) => (
          <div
            key={idx}
            css={css`
              width: 60%;
              margin: 0rem auto;
              text-align: center;

              ${mq[0]} {
                width: 100%;
                padding: 0 1.5rem;
              }
            `}
          >
            <Img
              fluid={item.logo.fluid}
              css={css`
                ${mq[0]} {
                  max-height: 60%;
                }
              `}
              imgStyle={{
                objectFit: "contain",
                margin: "0 auto",
                marginTop:
                  item.logo.fluid.src.includes("tugo") ||
                  item.logo.fluid.src.includes("gms")
                    ? "0.875rem"
                    : "",
                maxWidth: item.logo.fluid.src.includes("tugo") ? "85%" : "90%",
                maxHeight: item.logo.fluid.src.includes("tugo") ? "65%" : "80%",
                height: item.logo.fluid.src.includes("tugo") ? "90%" : "100%",
              }}
            />
          </div>
        ))}
      </div>
    </section>
  )
}

export default TrustingPartners
