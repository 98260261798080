import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { LabelStyled, PrimaryButton, SecondaryButton } from ".."
import { mq } from "../../utils/media-query"
import { useIntl } from "react-intl"

const InCACheck = ({ onLocInCanada, onLocNotInCanada, onGoBack }) => {
  const intl = useIntl()
  return (
    <Wrapper
      css={css`
        padding: 4rem 0;
        text-align: center;
      `}
    >
      <LabelStyled
        css={css`
          margin-bottom: 4rem;
        `}
      >
        {intl.formatMessage({ id: "gms.loc" })}
      </LabelStyled>
      <div className="btnWrapper">
        <PrimaryButton
          textSize="0.875rem"
          textTransform="capitalize"
          maxWidth="none"
          margin="0"
          padding="1rem 2.5rem"
          onClick={onLocInCanada}
        >
          {intl.formatMessage({ id: "btn.yes" })}
        </PrimaryButton>
        <SecondaryButton
          buttonColor="var(--clr-primary-100)"
          textSize="0.875rem"
          margin="0"
          padding="1rem 2.5rem"
          onClick={onLocNotInCanada}
        >
          {intl.formatMessage({ id: "btn.no" })}
        </SecondaryButton>
        <SecondaryButton
          buttonColor="var(--clr-neutral-400)"
          textSize="0.875rem"
          margin="none"
          onClick={onGoBack}
        >
          {intl.formatMessage({ id: "btn.goback" })}
        </SecondaryButton>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 90%;
  margin: 0 auto;

  .btnWrapper {
    display: grid;
    margin-top: 1rem;
    row-gap: 2rem;
    justify-items: center;

    ${mq[0]} {
      display: flex;
      justify-content: center;

      button {
        :not(:last-of-type) {
          margin-right: 3rem;
        }
      }
    }
  }
`
export default InCACheck
