import styled from "@emotion/styled"

const CardContainerStyled = styled.div`
  width: ${props => props.width || "100%"};
  max-width: ${props => props.maxWidth || "900px"};
  border: ${props => props.borderThickness || "2px"} solid
    ${props => props.borderColor || "var(--clr-primary-300)"};
  border-radius: ${props => props.borderRadius || "var(--border-radius-md)"};
  text-transform: ${props => props.textTransform || "capitalize"};
  letter-spacing: var(--letter-spacing-sm);
  font-family: var(--ff-tertiary);
  box-shadow: ${props => props.boxShadow || "var(--solid-shadow-primary300)"};
`

export default CardContainerStyled
