import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { PrimaryButton, SecondaryButton, LabelStyled } from ".."
import { useIntl } from "react-intl"

const FinalConfirmation = ({ onProceed, onGoBack, gmsWording }) => {
  const intl = useIntl()
  return (
    <Wrapper>
      <LabelStyled
        css={css`
          margin-bottom: 1rem;
          text-align: center;
        `}
      >
        {intl.formatMessage({ id: "gms.declare.title" })}
      </LabelStyled>
      <div
        css={css`
          margin-bottom: 3rem;
          line-height: 1.5;
        `}
      >
        {gmsWording.finalConfirmation.split("<br>").map((item, idx) => (
          <p key={idx}>{item}</p>
        ))}
      </div>
      <div className="btnWrapper">
        <PrimaryButton
          textSize="0.875rem"
          textTransform="capitalize"
          maxWidth="none"
          margin="0 4rem 0 0"
          onClick={onProceed}
        >
          {intl.formatMessage({ id: "btn.confirm" })}
        </PrimaryButton>
        <SecondaryButton
          buttonColor="var(--clr-neutral-400)"
          textSize="0.875rem"
          margin="none"
          onClick={onGoBack}
        >
          {intl.formatMessage({ id: "btn.goback" })}
        </SecondaryButton>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 90%;
  margin: 0 auto;

  .btnWrapper {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
  }

  .extraDisplayItem {
    margin: 1rem 0;
  }

  .expiryDate {
    grid-column: 1/-1;
  }

  .textDisplay {
    text-transform: none;
    padding: 1rem 1.25rem;
    font-size: 0.875rem;
    margin: 0;

    span {
      font-weight: bold;
    }
  }
`

export default FinalConfirmation
