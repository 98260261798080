import React from "react"
import { useSelector } from "react-redux"
import { css } from "@emotion/core"
import styled from "@emotion/styled"
import moment from "moment"
import { navigate } from "gatsby"
import { useIntl } from "react-intl"

import {
  CardContainerStyled,
  SecondaryButton,
  LabelStyled,
  InputDisplay,
} from "."

const TravelPlanDisplayCard = ({ plan, noEditBtn }) => {
  const intl = useIntl()
  const displayItems = {
    travelFrom: intl.formatMessage({ id: "details.from" }),
    travelTo: intl.formatMessage({ id: "details.to" }),
    startDate: intl.formatMessage({ id: "details.start" }),
    endDate: intl.formatMessage({ id: "details.end" }),
  }
  let travelPlan = useSelector(state => state.travelPlan)
  travelPlan = plan || travelPlan
  return (
    <CardContainerStyled
      maxWidth="700px"
      css={css`
        margin: 0 auto;
      `}
    >
      <Wrapper>
        <div className="topPortion">
          <LabelStyled fontSize="1.25rem" marginBottom="0">
            {intl.formatMessage({ id: "review.subtitle" })}
          </LabelStyled>
          <div>
            {!noEditBtn && (
              <SecondaryButton
                buttonColor="var(--clr-primary-100)"
                textSize="0.875rem"
                textWeight="700"
                padding="0.5rem 1rem"
                onClick={() => navigate("/get-quote-details")}
              >
                {intl.formatMessage({ id: "btn.edit" })}
              </SecondaryButton>
            )}
          </div>
        </div>
        <div className="divider"></div>
        <div className="btmPortion">
          {Object.keys(displayItems).map((key, idx) => (
            <InputDisplay
              label={displayItems[key]}
              key={idx}
              content={
                key.includes("Date")
                  ? moment(travelPlan[key]).format("YYYY-MM-DD")
                  : travelPlan[key]
              }
            />
          ))}
        </div>
      </Wrapper>
    </CardContainerStyled>
  )
}

const Wrapper = styled.div`
  .topPortion {
    display: flex;
    margin: 0 auto;
    padding: 1.5rem 3.5rem 1rem 3.5rem;
    justify-content: space-between;
    width: 100%;
  }

  .divider {
    margin: 0 3.5rem;
    height: 1px;
    background-color: var(--clr-primary-300);
  }

  .btmPortion {
    width: 100%;
    margin: 0 auto;
    padding: 2rem 3.5rem 3rem 3.5rem;
    grid-template-columns: 1fr;
    display: grid;
    justify-items: center;
    column-gap: 2rem;
    row-gap: 1.5rem;

    @media (min-width: 500px) {
      grid-template-columns: 1fr 1fr;
    }
  }
`

export default TravelPlanDisplayCard
