import React, { useState } from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { useSelector, useDispatch } from "react-redux"
import { useIntl } from "react-intl"

import { sportsCoverageOptions } from "../utils/quotesOptions"
import { getAge } from "../utils/time-related-func"
import ACTIONS from "../redux/actions/actionTypes"
import { RadioWithLabelSimple, LabelStyled } from "."

const TugoExtraOptions = ({ onChangeOpt }) => {
  const intl = useIntl()

  let options = Object.keys(sportsCoverageOptions)
  const dispatch = useDispatch()
  const travellers = useSelector(state => state.travellers)
  const optionsFromState = useSelector(state => state.options) || []
  const initCoverageNeedCheck = () => {
    for (const opt of optionsFromState) {
      if (
        Object.keys(sportsCoverageOptions).find(
          o => sportsCoverageOptions[o].code === opt.code
        )
      )
        return "Yes"
    }
    return "No"
  }
  const [needCover, setNeedCover] = useState(initCoverageNeedCheck())

  for (const traveller of travellers) {
    if (getAge(traveller.birth_date) < 21) {
      options = Object.keys(sportsCoverageOptions).filter(
        sport => !sportsCoverageOptions[sport]["21above"]
      )
      break
    }
  }

  const onSelectSport = sport => {
    if (sport === "None") {
      const preExisting = optionsFromState.find(
        opt => opt.code === "include-pre-existing-coverage"
      )
      dispatch({
        type: ACTIONS.DELETE_ALL_SPORT_OPTIONS,
        payload: preExisting,
      })
      setNeedCover("No")
      onChangeOpt()
      return
    }
    if (
      !optionsFromState.find(
        opt => opt.code === sportsCoverageOptions[sport].code
      )
    ) {
      dispatch({
        type: ACTIONS.SET_OPTIONS,
        payload: { code: sportsCoverageOptions[sport].code },
      })
      onChangeOpt()
      return
    }
    if (
      optionsFromState.find(
        opt => opt.code === sportsCoverageOptions[sport].code
      )
    ) {
      dispatch({
        type: ACTIONS.DELETE_OPTION,
        payload: { code: sportsCoverageOptions[sport].code },
      })
      onChangeOpt()
    }
  }

  const onChangeCoverNeed = val => {
    setNeedCover(val)
  }

  const selected = opt => {
    if (optionsFromState.find(o => o.code === sportsCoverageOptions[opt].code))
      return true
    return false
  }

  return (
    <div
      css={css`
        display: grid;
        grid-template-columns: 80% 1fr;
        grid-template-rows: 1fr;
        align-items: center;
        margin-bottom: 2rem;

        @media (min-width: 600px) {
          grid-template-columns: 360px 1fr;
        }
      `}
    >
      <LabelStyled textTransform="none">
        {intl.formatMessage({
          id: "extra",
        })}
        <p
          css={css`
            margin: 0;
          `}
        >
          {intl.formatMessage({
            id: "extraSub",
          })}
          :
        </p>
      </LabelStyled>
      <div
        css={css`
          display: inline-flex;
          flex-wrap: wrap;
        `}
      >
        {needCover === "Yes" ? (
          options.map(opt => (
            <OptionContainer key={opt} active={selected(opt)}>
              <p onClick={e => onSelectSport(opt)}>
                {intl.formatMessage({ id: opt })}
              </p>
            </OptionContainer>
          ))
        ) : (
          <RadioWithLabelSimple
            label=""
            options={[
              intl.formatMessage({
                id: "Yes",
              }),
              intl.formatMessage({
                id: "No",
              }),
            ]}
            name={"extraCover"}
            inputFromParent={needCover}
            onChangeVal={onChangeCoverNeed}
            wordsMapping={{
              [intl.formatMessage({
                id: "Yes",
              })]: "Yes",
              [intl.formatMessage({
                id: "No",
              })]: "No",
            }}
          />
        )}
      </div>
    </div>
  )
}

const OptionContainer = styled.div`
  font-size: 0.75rem;
  font-family: var(--ff-tertiary);
  letter-spacing: var(--letter-spacing-sm);
  text-align: center;
  font-weight: bold;

  ${props =>
    !props.active &&
    !props.grayout &&
    ":hover { p {color: var(--clr-primary-100);border: 1px solid var(--clr-primary-100); }}"}

  p {
    border: 1px solid
      ${props =>
        props.grayout ? "var(--clr-neutral-300)" : "var(--clr-primary-300)"};
    border-radius: var(--border-radius);
    margin: 0.25rem;
    padding: 0.25rem;
    color: ${props =>
      props.active
        ? "var(--clr-neutral-100)"
        : props.grayout
        ? "var(--clr-neutral-300)"
        : "var(--clr-primary-300)"};
    box-shadow: ${props => (props.active ? "none" : "var(--dark-shadow-sb)")};
    cursor: pointer;
    pointer-events: auto;
    background: ${props =>
      props.active ? "var(--clr-primary-300)" : "var(--clr-neutral-100)"};
    user-select: none;
    transition: var(--transition);
  }
`

export default TugoExtraOptions
