import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"

const Input = ({ placeholder, type, name, inputStyle, onChange, invalid }) => {
  return (
    <StyledInput
      type={type}
      name={name}
      placeholder={placeholder}
      css={css`
        ${inputStyle}
      `}
      onChange={onChange}
      invalid={invalid}
    />
  )
}

const Textarea = ({
  placeholder,
  type,
  name,
  inputStyle,
  rows,
  onChange,
  invalid,
}) => {
  return (
    <StyledTextarea
      type={type}
      name={name}
      rows={rows}
      placeholder={placeholder}
      css={css`
        ${inputStyle}
      `}
      onChange={onChange}
      invalid={invalid}
    />
  )
}

const StyledTextarea = styled.textarea`
  display: block;
  width: 100%;
  padding: 0.75rem 1rem;
  margin-bottom: 1.25rem;
  border: 2px solid
    ${props =>
      props.invalid ? "var(--clr-primary-400)" : "var(--clr-primary-300)"};
  border-radius: var(--border-radius);
  text-transform: capitalize;
  letter-spacing: var(--letter-spacing-sm);
  font-family: var(--ff-tertiary);

  &::placeholder {
    color: var(--clr-neutral-300);
    text-transform: capitalize;
    letter-spacing: var(--letter-spacing-sm);
  }
`

const StyledInput = styled.input`
  display: block;
  width: 100%;
  padding: 0.75rem 1rem;
  margin-bottom: 1.25rem;
  border: 2px solid
    ${props =>
      props.invalid ? "var(--clr-primary-400)" : "var(--clr-primary-300)"};
  border-radius: var(--border-radius);
  letter-spacing: var(--letter-spacing-sm);
  font-family: var(--ff-tertiary);

  &::placeholder {
    color: var(--clr-neutral-300);
    text-transform: capitalize;
    letter-spacing: var(--letter-spacing-sm);
  }
`

export { Input, Textarea }
