import ACTIONS from "../actions/actionTypes"

export const coverage = (state = 50000, action) => {
  switch (action.type) {
    case ACTIONS.SET_COVERAGE:
      return action.payload
    default:
      return state
  }
}
export const deductible = (state = 0, action) => {
  switch (action.type) {
    case ACTIONS.SET_DEDUCTIBLE:
      return action.payload
    default:
      return state
  }
}

export const unavailableCoverages = (state = [], action) => {
  if (action.type === ACTIONS.SET_UNAVAILABLE_COVERAGES) {
    return action.payload
  }
  return state
}

export const unavailableDeductibles = (state = [], action) => {
  if (action.type === ACTIONS.SET_UNAVAILABLE_DEDUCTIBLES) {
    return action.payload
  }
  return state
}

export const allCoveragesDeductibles = (state = {}, action) => {
  if (action.type === ACTIONS.SET_ALL_COVERAGES_DEDUCTIBLES) {
    return action.payload
  }
  return state
}
