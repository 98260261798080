import React from "react"
import { Link } from "gatsby"
import { useIntl } from "react-intl"
import linkItems from "../utils/links"

const Links = ({ ulStyle, linkStyle, liStyle, links = linkItems }) => {
  const intl = useIntl()
  const UlStyled = ({ children }) => <ul css={ulStyle}>{children}</ul>
  const LinkStyled = ({ to, children }) => (
    <Link
      css={{
        ...linkStyle,
      }}
      to={to}
      activeClassName="current-page"
    >
      {children}
    </Link>
  )
  const LiStyled = ({ children }) => <li css={liStyle}>{children}</li>

  const linkItems = links.map(link => (
    <LiStyled key={link.label}>
      <LinkStyled to={link.url}>
        {intl.formatMessage({ id: link.id })}
      </LinkStyled>
    </LiStyled>
  ))

  return <UlStyled>{linkItems}</UlStyled>
}

export default Links
