import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { useSelector } from "react-redux"
import { useIntl } from "react-intl"

const PreExistingCondition = ({ onChange }) => {
  const intl = useIntl()
  const preExisting = useSelector(state => state.preExisting)
  return (
    <div
      css={css`
        display: grid;
        grid-template-columns: 80% 1fr;
        align-items: center;
        margin-bottom: 2rem;

        @media (min-width: 600px) {
          grid-template-columns: 360px 1fr;
        }
      `}
    >
      <LabelStyled>
        {intl.formatMessage({ id: "policies.preexisting" })}
      </LabelStyled>
      <ToggleContainer>
        <input
          type="radio"
          id="yes"
          value="include"
          checked={preExisting}
          name="switch"
          className="btn-switch__radio btn-switch__radio_yes"
          onChange={onChange}
        />
        <input
          type="radio"
          checked={!preExisting}
          id="no"
          value="notInclude"
          name="switch"
          className="btn-switch__radio btn-switch__radio_no"
          onChange={onChange}
        />
        <label
          htmlFor="yes"
          className="btn-switch__label btn-switch__label_yes"
        >
          <span className="btn-switch__txt">&#10004;</span>
        </label>
        <label htmlFor="no" className="btn-switch__label btn-switch__label_no">
          <span className="btn-switch__txt">&#10008;</span>
        </label>
      </ToggleContainer>
    </div>
  )
}

const ToggleContainer = styled.div`
  position: relative;
  user-select: none;
  display: inline-block;
  justify-self: start;
  width: 75px;
  height: 35px;

  .btn-switch__radio {
    display: none;
    width: 100%;
  }
  .btn-switch__label {
    display: inline-block;
    padding: 0.3rem 0.15rem 0.4rem 1rem;
    vertical-align: top;
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.5;
    color: var(--clr-primary-300);
    cursor: pointer;
    transition: color 0.2s ease-in-out;
  }
  .btn-switch__label + .btn-switch__label {
    padding-right: 0.25rem;
    padding-left: 0.25rem;
  }
  .btn-switch__txt {
    position: relative;
    z-index: 2;
    display: inline-block;
    min-width: 1.5rem;
    opacity: 1;
    pointer-events: none;
    transition: opacity 0.2s ease-in-out;
  }
  .btn-switch__radio_no:checked ~ .btn-switch__label_yes .btn-switch__txt,
  .btn-switch__radio_yes:checked ~ .btn-switch__label_no .btn-switch__txt {
    opacity: 0;
  }
  .btn-switch__label:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgb(9, 31, 82, 0.2);
    border-radius: var(--border-radius-md);
    transition: background 0.2s ease-in-out;
  }
  .btn-switch__radio_yes:checked ~ .btn-switch__label:before {
    background: var(--clr-primary-300);
  }
  .btn-switch__label_no:after {
    content: "";
    position: absolute;
    z-index: 2;
    top: 0.25rem;
    bottom: 0.25rem;
    left: 0.25rem;
    width: 1.75rem;
    background: #fff;
    border-radius: var(--border-radius);
    pointer-events: none;
    /* box-shadow: 0 0.1429em 0.2143em rgba(43, 43, 43, 0.2),
      0 0.3572em 0.3572em rgba(43, 43, 43, 0.1); */
    transition: left 0.2s ease-in-out, background 0.2s ease-in-out;
  }
  .btn-switch__radio_yes:checked ~ .btn-switch__label_no:after {
    left: calc(100% - 2rem);
    background: #fff;
  }
  .btn-switch__radio_no:checked ~ .btn-switch__label_yes:before,
  .btn-switch__radio_yes:checked ~ .btn-switch__label_no:before {
    z-index: 1;
  }
  .btn-switch__radio_yes:checked ~ .btn-switch__label_yes {
    color: #fff;
  }
`

const LabelStyled = styled.label`
  font-size: 1.1rem;
  color: var(--clr-primary-300);
  font-family: var(--ff-tertiary);
  font-weight: bold;
  letter-spacing: var(--letter-spacing-sm);
  margin: auto 1rem auto 0;
  text-transform: capitalize;
`
export default PreExistingCondition
