import React from "react"
import styled from "@emotion/styled"
import { AiFillWechat, AiOutlineZhihu, FaLinkedin } from "react-icons/all"

const data = [
  {
    id: 1,
    icon: <AiFillWechat></AiFillWechat>,
    url: "https://www.twitter.com",
  },
  {
    id: 2,
    icon: <FaLinkedin></FaLinkedin>,
    url: "https://www.linkedin.com/company/iware-travel/",
  },
  {
    id: 3,
    icon: <AiOutlineZhihu></AiOutlineZhihu>,
    url: "https://www.twitter.com",
  },
]

const AStyled = styled.a`
  font-size: 2.5rem;
  color: var(--clr-primary-200);
  transition: var(--transition);
  &:hover {
    color: var(--clr-neutral-400);
  }
`

const UlStyled = styled.ul`
  margin-top: 2.5rem;
  display: flex;
  justify-content: space-around;
`

export default ({ liStyle }) => {
  const links = data.map(link => {
    return (
      <li key={link.id} css={liStyle}>
        <AStyled href={link.url}>{link.icon}</AStyled>
      </li>
    )
  })
  return <UlStyled>{links}</UlStyled>
}
