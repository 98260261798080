import React from "react"
import { css } from "@emotion/core"

import Footer from "./Footer"
import NavBar from "./NavBar"
import Sidebar from "./Sidebar"

const Layout = ({ bkgColor, children }) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const toggle = () => {
    setIsOpen(!isOpen)
  }
  return (
    <>
      <NavBar open={toggle} bkgColor={bkgColor} />
      <Sidebar showSidebar={isOpen} closeSidebar={toggle} />
      <main
        css={css`
          background-color: var(--clr-neutral-100);
        `}
      >
        {children}
      </main>
      <Footer />
    </>
  )
}

export default Layout
