import React from "react"
import { css } from "@emotion/core"
import styled from "@emotion/styled"
import { useIntl } from "react-intl"

import { CardContainerStyled, InputWithLabelSessionStorage } from "."

const PaymentInfoCard = () => {
  const intl = useIntl()
  const prevEmail =
    typeof window !== "undefined" && window.sessionStorage.getItem("email")

  const paymentInfoValidation = {
    email: [
      {
        validateFunc: email => email.match(/\S+@\S+\.\S+/) !== null,
        noneEligibleMessage: intl.formatMessage({ id: "error.validEmail" }),
      },
    ],
  }

  const paymentInfoItems = {
    email: {
      label: intl.formatMessage({ id: "purchase.email" }),
    },
    confirmEmail: {
      label: intl.formatMessage({ id: "purchase.email2" }),
    },
    phone: {
      label: intl.formatMessage({ id: "purchase.phone" }),
    },
    wechat: {
      label: intl.formatMessage({ id: "purchase.wechat" }),
    },
    address: {
      label: intl.formatMessage({ id: "purchase.address" }),
      textArea: true,
    },
    city: {
      label: intl.formatMessage({ id: "purchase.city" }),
    },
    province: {
      label: intl.formatMessage({ id: "purchase.prov" }),
    },
    postalCode: {
      label: intl.formatMessage({ id: "purchase.postal" }),
    },
  }

  return (
    <CardContainerStyled
      maxWidth="700px"
      css={css`
        margin: 0 auto;
        text-transform: none;
      `}
    >
      <Wrapper>
        <div className="innerWrapper">
          {Object.keys(paymentInfoItems).map(item => (
            <InputWithLabelSessionStorage
              key={item}
              label={paymentInfoItems[item].label}
              sessionStorageKey={item}
              required={item !== "wechat"}
              validateVal={
                item === "confirmEmail"
                  ? {
                      ...paymentInfoValidation,
                      confirmEmail: [
                        {
                          validateFunc: confirmEmail =>
                            prevEmail === confirmEmail,
                          noneEligibleMessage: intl.formatMessage({
                            id: "error.emailMatch",
                          }),
                        },
                      ],
                    }
                  : paymentInfoValidation
              }
              labelFontWeight="normal"
              inputFontWeight="bold"
              labelFontSize="0.875rem"
              inputTextTransform="none"
              isTextArea={paymentInfoItems[item].textArea}
              placeholder={item === "phone" ? "000-000-0000" : ""}
            />
          ))}
        </div>
      </Wrapper>
    </CardContainerStyled>
  )
}

const Wrapper = styled.div`
  .innerWrapper {
    padding: 2rem 4rem;
    display: grid;
    row-gap: 1rem;
    column-gap: 4rem;

    @media (min-width: 605px) {
      grid-template-rows: 1fr 1fr 1fr 1fr;
      grid-template-columns: 1fr 1fr;
    }
  }
`

export default PaymentInfoCard
