import React from "react"
import { RiLoader5Line } from "react-icons/ri"
import styled from "@emotion/styled"

const Spinner = () => {
  return (
    <Wrapper>
      <IconAnimated />
    </Wrapper>
  )
}

const IconAnimated = styled(RiLoader5Line)`
  font-size: 4rem;
  color: var(--clr-primary-200);

  animation: spin 0.75s linear infinite;
  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`

const Wrapper = styled.div`
  text-align: center;
  min-height: 20rem;
`

export default Spinner
