import ACTIONS from "./actionTypes"

export const setTravellers = traveller => ({
  type: ACTIONS.SET_TRAVELLERS,
  payload: traveller,
})

export const deleteTraveller = traveller => ({
  type: ACTIONS.DELETE_TRAVELLER,
  payload: traveller,
})
