import React from "react"
import { useSelector } from "react-redux"
import styled from "@emotion/styled"
import { InfoCard, PrimaryButton, SecondaryButton } from "../"
import { getTravellerAges } from "../../utils/time-related-func"
import { useIntl } from "react-intl"

const EligibilityCheck = ({ onProceed, onGoBack, gmsWording }) => {
  const intl = useIntl()
  const travellers = useSelector(state => state.travellers)
  const travellerAges = getTravellerAges(travellers)
  const containsBothGroups =
    travellerAges.length > 1 &&
    travellerAges.some(age => age < 55) &&
    travellerAges.some(age => age >= 55)

  return (
    <Wrapper>
      <InnerWrapper>
        {containsBothGroups ? (
          <>
            <InfoCard
              color={gmsWording["55orOlder"].color}
              key={gmsWording["55orOlder"].label}
              data={gmsWording["55orOlder"].wording}
              label={gmsWording["55orOlder"].label}
              fontSize={gmsWording["55orOlder"].fontSize}
            />
            <InfoCard
              color={gmsWording["under55"].color}
              key={gmsWording["under55"].label}
              data={gmsWording["under55"].wording}
              label={gmsWording["under55"].label}
              fontSize={gmsWording["under55"].fontSize}
            />
          </>
        ) : travellerAges[0] < 55 ? (
          <InfoCard
            color={gmsWording["under55"].color}
            key={gmsWording["under55"].label}
            data={gmsWording["under55"].wording}
            label={gmsWording["under55"].label}
            fontSize={gmsWording["under55"].fontSize}
          />
        ) : (
          <InfoCard
            color={gmsWording["55orOlder"].color}
            key={gmsWording["55orOlder"].label}
            data={gmsWording["55orOlder"].wording}
            label={gmsWording["55orOlder"].label}
            fontSize={gmsWording["55orOlder"].fontSize}
          />
        )}
      </InnerWrapper>
      <div className="btnWrapper">
        <PrimaryButton
          textSize="0.875rem"
          textTransform="capitalize"
          maxWidth="none"
          margin="0 4rem 0 0"
          onClick={onProceed}
        >
          {containsBothGroups
            ? intl.formatMessage({ id: "btn.gms.eligibleWe" })
            : intl.formatMessage({ id: "btn.gms.eligibleI" })}
        </PrimaryButton>
        <SecondaryButton
          buttonColor="var(--clr-neutral-400)"
          textSize="0.875rem"
          margin="none"
          onClick={onGoBack}
        >
          {intl.formatMessage({ id: "btn.goback" })}
        </SecondaryButton>
      </div>
    </Wrapper>
  )
}

const InnerWrapper = styled.div`
  max-width: 700px;
  margin: 0 auto;
`

const Wrapper = styled.div`
  width: 90%;
  margin: 0 auto;

  .btnWrapper {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
  }
`

export default EligibilityCheck
