import React from "react"
import styled from "@emotion/styled"
import { navigate } from "gatsby"
import { useDispatch } from "react-redux"
import { useIntl } from "react-intl"

import { InfoCard, PrimaryButton, SecondaryButton } from "."
import { mq } from "../utils/media-query"
import { setEligibility } from "../redux/actions"

const DestinPurchaseFlow = () => {
  const intl = useIntl()
  const destinWording = {
    [intl.formatMessage({ id: "destin.notAvailable" })]: {
      color: "var(--clr-primary-400)",
      wording: [
        {
          content: [
            intl.formatMessage({ id: "destin.notAvailContent1" }),
            intl.formatMessage({ id: "destin.notAvailContent2" }),
            intl.formatMessage({ id: "destin.notAvailContent3" }),
            intl.formatMessage({ id: "destin.notAvailContent4" }),
            intl.formatMessage({ id: "destin.notAvailContent5" }),
            intl.formatMessage({ id: "destin.notAvailContent6" }),
          ],
        },
      ],
    },
    [intl.formatMessage({ id: "destin.eligible" })]: {
      color: "var(--clr-primary-200)",
      wording: [
        {
          content: [
            intl.formatMessage({ id: "destin.eligibleContent1" }),
            intl.formatMessage({ id: "destin.eligibleContent2" }),
            intl.formatMessage({ id: "destin.eligibleContent3" }),
          ],
        },
      ],
    },
    [intl.formatMessage({ id: "destin.waiting" })]: {
      color: "var(--clr-neutral-400)",
      fontSize: "0.75rem",
      wording: [
        {
          subCap: intl.formatMessage({ id: "destin.0to85" }),
          content: [
            intl.formatMessage({ id: "destin.0to85Content1" }),
            intl.formatMessage({ id: "destin.0to85Content2" }),
          ],
        },
        {
          subCap: intl.formatMessage({ id: "destin.85andOver" }),
          content: [
            intl.formatMessage({ id: "destin.85andOverContent1" }),
            intl.formatMessage({ id: "destin.85andOverContent2" }),
          ],
        },
        {
          subCap: intl.formatMessage({ id: "destin.waived" }),
          content: [
            intl.formatMessage({ id: "destin.waivedContent1" }),
            intl.formatMessage({ id: "destin.waivedContent2" }),
          ],
        },
      ],
    },
  }

  const destinItems = Object.keys(destinWording)
  const dispatch = useDispatch()
  const confirmEligibility = () => {
    dispatch(setEligibility(true))
    navigate("/get-quote-purchase/review")
  }

  return (
    <Wrapper>
      <InnerWrapper>
        {destinItems.map((item, index) => (
          <InfoCard
            color={destinWording[item].color}
            key={item}
            data={destinWording[item].wording}
            label={item}
            className={index === destinItems.length - 1 ? "span-2" : ""}
            fontSize={destinWording[item].fontSize}
          />
        ))}
      </InnerWrapper>
      <div className="btnWrapper">
        <PrimaryButton
          textSize="0.875rem"
          textTransform="capitalize"
          maxWidth="none"
          margin="0 4rem 0 0"
          width="135px"
          padding="0.5rem 0.75rem"
          onClick={confirmEligibility}
        >
          {intl.formatMessage({ id: "btn.understand+eligible" })}
        </PrimaryButton>
        <SecondaryButton
          buttonColor="var(--clr-neutral-400)"
          textSize="0.875rem"
          margin="none"
          onClick={() => navigate("/get-quote-policies")}
        >
          {intl.formatMessage({ id: "btn.goback" })}
        </SecondaryButton>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 90%;
  margin: 0 auto;

  .btnWrapper {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
  }
`

const InnerWrapper = styled.div`
  display: block;

  ${mq[0]} {
    display: grid;
    grid-template-columns: 50% 50%;
    column-gap: 4rem;
  }

  .span-2 {
    grid-column: 1 / -1;
  }
`

export default DestinPurchaseFlow
