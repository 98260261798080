import ACTIONS from "../actions/actionTypes"
import moment from "moment"

const initState = {
  travelFrom: "",
  travelTo: "",
  startDate: null,
  endDate: null,
  arrivalDate: null,
  applicationDate: moment().format("YYYY-MM-DD"),
}

export const travelPlan = (state = initState, action) => {
  if (action.type === ACTIONS.SET_TRAVEL_PLAN)
    return {
      ...state,
      ...action.payload,
    }
  return state
}
