import React from "react"
import { css } from "@emotion/core"
import { useIntl } from "react-intl"

import infoExplanation from "../utils/infoExplaination"

const InfoCard = ({ insurer, content }) => {
  const intl = useIntl()
  return (
    <div
      css={css`
        position: absolute;
        background-color: var(--clr-neutral-100);
        border-radius: var(--border-radius-md) var(--border-radius-md)
          var(--border-radius-md) 0;
        z-index: 1;
        top: 0;
        left: 100%;
        transform: translate(0%, -100%);
        box-shadow: var(--dark-shadow-sb);
        width: 15rem;
        padding: 1rem;
        font-family: var(--ff-tertiary);
      `}
    >
      <p
        css={css`
          text-transform: capitalize;
          font-weight: bold;
          font-size: 0.875rem;
        `}
      >
        {intl.formatMessage({ id: content })}
      </p>
      <div
        css={css`
          font-size: 0.75rem;
        `}
      >
        {intl
          .formatMessage({ id: `${insurer}.${content}` })
          .split("<br>")
          .map((item, idx) => (
            <p key={idx}>{item}</p>
          ))}
      </div>
    </div>
  )
}

export default InfoCard
