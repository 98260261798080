import {
  isAtLeast18Yrs,
  isAtLeast15days,
  isValidDate,
  noLaterThanToday,
  at21OrYounger,
} from "./time-related-func"

export const validDate = [
  {
    validateFunc: isValidDate,
    noneEligibleMessage: "error.bday",
  },
  {
    validateFunc: noLaterThanToday,
    noneEligibleMessage: "error.bdayUnborn",
  },
]
export const policyholderValidDate = [
  {
    validateFunc: isAtLeast18Yrs,
    noneEligibleMessage: "error.18year",
  },
]
export const dependantValidDate = [
  {
    validateFunc: at21OrYounger,
    noneEligibleMessage: "dependantAge",
  },
]

export const all = [
  {
    validateFunc: isAtLeast15days,
    noneEligibleMessage: "error.15days",
  },
]
