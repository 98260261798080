import React from "react"
import { css } from "@emotion/core"
import { useIntl } from "react-intl"

import Title from "./Title"
import { mq } from "../utils/media-query"
import whyBuyFromCACards from "../utils/whyBuyFromCACardData"
import Card from "./Card"

const WhyBuyFromCanada = () => {
  const intl = useIntl()
  return (
    <section
      css={css`
        padding: 5rem 0;
      `}
    >
      <Title title={intl.formatMessage({ id: "home.whyCap" })} />
      <div
        css={css`
          width: 90vw;
          margin: 0 auto;
          max-width: 1170px;

          ${mq[0]} {
            display: grid;
            grid-template-columns: 1fr 1fr;
            -webkit-column-gap: 2rem;
            -moz-column-gap: 2rem;
            column-gap: 2rem;
          }
          /* 992px */
          ${mq[1]} {
            width: 95vw;
            grid-template-columns: 1fr 1fr 1fr;
          }
        `}
      >
        {whyBuyFromCACards.map(cardItem => (
          <Card key={cardItem.caption} {...cardItem} />
        ))}
      </div>
    </section>
  )
}

export default WhyBuyFromCanada
