import React from "react"
import styled from "@emotion/styled"
import { useDispatch } from "react-redux"
import { useIntl } from "react-intl"

import { LabelStyled } from "."
import ACTIONS from "../redux/actions/actionTypes"

const InfoCardCheckbox = ({
  label,
  data,
  color,
  className,
  fontSize,
  traveller,
}) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const onChangeQuestionAnswer = (e, qCode, exclusive) => {
    const qAnswer = { code: e.target.name, value: e.target.checked ? 1 : 0 }
    const newQuestions = traveller.mq.questions.map(q => {
      if (q.code === qCode) {
        return Object.assign(q, {
          answers: q.answers.map((a, idx) => {
            return a.code === qAnswer.code
              ? qAnswer
              : exclusive
              ? { code: a.code, value: 0 }
              : idx === q.answers.length - 1
              ? { code: a.code, value: 0 }
              : a
          }),
        })
      }
      return q
    })
    const newMQ = {
      code: "QU-VMQ-1",
      questions: newQuestions,
    }
    dispatch({
      type: ACTIONS.SET_TRAVELLERS,
      payload: Object.assign(traveller, { mq: newMQ }),
    })
  }

  return (
    <div className={className}>
      <LabelStyled textTransform="none" marginBottom="0.75rem">
        {label}
      </LabelStyled>
      <Wrapper>
        {data.map((dataGroup, idx) => (
          <Container
            key={idx}
            borderColor={color}
            shadowColor={color}
            fontSize={fontSize}
          >
            {dataGroup.subCap && (
              <p className="subCap">
                {intl.formatMessage({ id: dataGroup.subCap })}
              </p>
            )}
            {dataGroup.extraLine && (
              <p className="explainer">
                {intl
                  .formatMessage({ id: dataGroup.extraLine })
                  .includes("<br>")
                  ? intl
                      .formatMessage({ id: dataGroup.extraLine })
                      .split("<br>")
                      .map((chunk, idx) => <span key={idx}>{chunk}</span>)
                  : intl.formatMessage({ id: dataGroup.extraLine })}
              </p>
            )}
            {dataGroup.content.map((dataPiece, index) => {
              return (
                <div
                  className={index % 2 === 0 ? "even item" : "odd item"}
                  key={index}
                >
                  <StyledCheckboxInput
                    id={dataPiece.code}
                    type="checkbox"
                    name={dataPiece.code}
                    checked={
                      traveller.mq.questions
                        .find(q => q.code === dataGroup.code)
                        .answers.find(a => a.code === dataPiece.code)?.value ===
                      1
                    }
                    onChange={e =>
                      onChangeQuestionAnswer(
                        e,
                        dataGroup.code,
                        dataPiece.exclusive
                      )
                    }
                  />
                  <label htmlFor={dataPiece.code}>
                    {intl.formatMessage({ id: dataPiece.q }).includes("<br>")
                      ? intl
                          .formatMessage({ id: dataPiece.q })
                          .split("<br>")
                          .map((chunk, idx) => <span key={idx}>{chunk}</span>)
                      : intl.formatMessage({ id: dataPiece.q })}
                  </label>
                </div>
              )
            })}
          </Container>
        ))}
      </Wrapper>
    </div>
  )
}

const Wrapper = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  column-gap: 1.25rem;
  row-gap: 3rem;
  margin-bottom: 2.5rem;
`

const Container = styled.div`
  border-radius: var(--border-radius-md);
  border: solid 1px ${props => props.borderColor || "var(--clr-primary-300)"};
  box-shadow: 0.2rem 0.2rem 0 0
    ${props => props.shadowColor || "var(--clr-primary-300)"};
  font-family: var(--ff-quaternary);
  font-size: ${props => props.fontSize || "1rem"};

  .even {
    background-color: var(--clr-neutral-200lighter);
  }
  .explainer {
    color: var(--clr-neutral-400);
    font-size: 0.875rem;
    padding: 0 1.5rem;
    margin-top: -0.5rem;
    margin-left: 0.85rem;
    font-family: var(--ff-tertiary);
  }

  .subCap {
    margin: 0.5rem 0;
    padding: 1.25rem 1.5rem;
    font-weight: bold;

    :first-of-type {
      border-top-right-radius: var(--border-radius-md);
      border-top-left-radius: var(--border-radius-md);
    }
  }

  .item {
    margin: 0;
    padding: 1.25rem 1.5rem;
    display: flex;

    :last-of-type {
      border-bottom-right-radius: var(--border-radius-md);
      border-bottom-left-radius: var(--border-radius-md);
    }

    span {
      display: block;
      :not(:first-of-type) {
        margin-left: 1.5rem;
      }
    }

    input {
      width: 24px;
    }

    input,
    label,
    p {
      margin: 0;
      margin-right: 1rem;
    }
  }
`

const StyledCheckboxInput = styled.input`
  --active: var(--clr-neutral-100);
  --active-inner: var(--clr-primary-300);
  --focus: 2px rgba(9, 31, 82, 0.2);
  --border: var(--clr-primary-300);
  --border-hover: rgba(9, 31, 82, 0.5);
  --background: #fff;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: 10%;
  height: 24px;
  width: 24px;
  outline: none;
  display: inline-block;
  vertical-align: top;
  position: relative;
  margin: 0;
  cursor: pointer;
  border: 1px solid var(--bc, var(--border));
  background: var(--b, var(--background));
  transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;

  &:after {
    content: "";
    display: block;
    left: 0;
    top: 0;
    position: absolute;
    transition: transform var(--d-t, 0.3s) var(--d-t-e, ease),
      opacity var(--d-o, 0.2s);
    opacity: var(--o, 0);
    width: 7px;
    height: 12px;
    border: 2px solid var(--active-inner);
    border-top: 0;
    border-left: 0;
    left: 7px;
    top: 3px;
    transform: rotate(var(--r, 20deg));
  }

  &:checked {
    --b: var(--active);
    --bc: var(--active-inner);
    --o: 1;
    --d-o: 0.3s;
    --d-t: 0.6s;
    --d-t-e: cubic-bezier(0.2, 0.85, 0.32, 1.2);
    --r: 43deg;
  }

  &:hover {
    &:not(:checked) {
      &:not(:disabled) {
        --bc: var(--border-hover);
      }
    }
  }

  &:focus {
    box-shadow: 0 0 0 var(--focus);
  }

  & + label {
    font-size: 1rem;
    line-height: 21px;
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
    margin-left: 4px;
  }
`

export default InfoCardCheckbox
