import React from "react"
import styled from "@emotion/styled"
import { connect } from "react-redux"
import { useIntl } from "react-intl"

import { mq } from "../utils/media-query"

const Stepper = ({ currentPath }) => {
  const intl = useIntl()
  const getPageIdx = currentPath => {
    if (!currentPath || currentPath == "/") return
    const address = currentPath.split("-")[2].split("/")[0]
    const pageIdx = stepDescs.indexOf(address)
    return pageIdx
  }

  const stepDescs = ["details", "travellers", "policies", "purchase"]

  let pageIdx = getPageIdx(currentPath)

  const steps = stepDescs.map((desc, idx) => (
    <div
      className={`${
        pageIdx < idx
          ? "step-tocome"
          : pageIdx === idx
          ? "step-current"
          : "step-done"
      } step-wrapper`}
      key={desc}
    >
      {idx !== stepDescs.length - 1 && <div className="divider"></div>}
      <div className="outer-circle">
        <div className="inner-circle"></div>
      </div>
      <div className="step-desc">
        {intl.formatMessage({ id: "stp." + desc })}
      </div>
    </div>
  ))

  return <StepperWrapper>{steps}</StepperWrapper>
}

const StepperWrapper = styled.div`
  display: none;

  ${mq[0]} {
    display: flex;
    justify-content: space-between;

    .step-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 23%;
      position: relative;
    }

    .outer-circle {
      border-radius: 80%;
      border: 4px solid var(--clr-primary-100);
      width: 24px;
      height: 24px;
      text-align: center;
      position: relative;
      margin-bottom: 0.2rem;

      .inner-circle {
        border-radius: 80%;
        width: 12px;
        height: 12px;
        background-color: var(--clr-primary-100);
        position: absolute;
        left: 2px;
        top: 2px;
      }
    }

    .step-desc {
      color: var(--clr-primary-100);
      font-family: var(--ff-tertiary);
      text-transform: capitalize;
      font-size: 0.75rem;
    }

    .divider {
      height: 3px;
      background-color: var(--clr-neutral-300);
      position: absolute;
      width: 80%;
      top: 25%;
      left: 60%;
    }

    .step-done {
      .inner-circle {
        display: none;
      }
      .outer-circle {
        background-color: var(--clr-primary-100);
      }
      .divider {
        background-color: var(--clr-primary-100);
      }
    }
    .step-tocome {
      .inner-circle {
        display: none;
      }
      .outer-circle {
        border: 4px solid var(--clr-neutral-300);
      }
      .step-desc {
        color: var(--clr-neutral-300);
      }
    }
    .step-current {
      .outer-circle {
        border: 4px solid var(--clr-primary-100);
      }
      .step-desc {
        color: var(--clr-primary-100);
      }
    }
  }
`

export default connect(({ currentPath }) => ({ currentPath }))(Stepper)
