export const paymentInfoValidation = {
  email: [
    {
      validateFunc: email => email.match(/\S+@\S+\.\S+/) !== null,
      noneEligibleMessage: "Please enter a valid email address.",
    },
  ],
}

export const paymentInfoItems = {
  email: {
    label: "email",
  },
  confirmEmail: {
    label: "confirm email",
  },
  phone: {
    label: "phone",
  },
  wechat: {
    label: "weChat username",
  },
  address: {
    label: "full residence address",
    textArea: true,
  },
  city: {
    label: "city",
  },
  province: {
    label: "province",
  },
  postalCode: {
    label: "postal code",
  },
}

export const paymentItems = [
  "cardHolderName",
  "cardNumber",
  "expireMonth",
  "expireYear",
  "cvc",
]
