import React from "react"
import { Link } from "gatsby"
import { css } from "@emotion/core"
import styled from "@emotion/styled"
import { useIntl } from "react-intl"

import fillout from "../../images/fillout.svg"
import { Title, SectionCenter } from "."
import { mq } from "../utils/media-query"

const GoBack = ({ backToLink, notFilledOut, text, actionText }) => {
  const intl = useIntl()
  return (
    <>
      <Title
        title={intl.formatMessage({ id: "redirect.title" })}
        fontColor="var(--clr-primary-300)"
      />
      <SectionCenter>
        <div
          css={css`
            width: 90%;
            margin: 0 auto;
            text-align: center;
            p {
              font-size: 1rem;
            }
            span {
              font-weight: bold;
            }
          `}
        >
          <p>
            {text || intl.formatMessage({ id: "redirect.text2" })}
            <span>{`${notFilledOut}`}</span>,{" "}
            <LinkStyled to={backToLink}>
              {actionText || intl.formatMessage({ id: "redirect.action2" })}
            </LinkStyled>
          </p>
          <img
            src={fillout}
            alt="fill-out-missing-info"
            css={css`
              width: 50%;
              margin-top: 2rem;

              ${mq[0]} {
                width: 40%;
                margin-top: 4rem;
              }
            `}
          />
        </div>
      </SectionCenter>
    </>
  )
}

const LinkStyled = styled(Link)`
  color: var(--clr-primary-100);
  transition: var(--transition);
  border-bottom: 2px dotted var(--clr-primary-100);

  :hover,
  :active {
    color: var(--clr-primary-200);
    border-bottom: 2px solid var(--clr-primary-200);
    transition: var(--transition);
  }
`

export default GoBack
