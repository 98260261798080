import React from "react"
import styled from "@emotion/styled"

import video from "../videos/learnmore.mp4"

const RegVideo = () => {
  return (
    <RegVideoWrapper>
      <video controls>
        <source src={video} type="video/mp4" />
        <track kind="captions" />
      </video>
    </RegVideoWrapper>
  )
}

const RegVideoWrapper = styled.div`
  width: 90vw;
  max-width: 700px;
  position: relative;
  margin: 0 auto 1rem auto;
  video {
    width: 100%;
    object-fit: contain;
    outline: none;
    border: 2px solid var(--clr-primary-300);
    box-shadow: 0.3rem 0.3rem 0 0 var(--clr-primary-300);
  }
`

export default RegVideo
