import { createStore, applyMiddleware, compose } from "redux"
import thunk from "redux-thunk"
import rootReducer from "./reducers"

export const initStore = () => {
  const middleware = [thunk]
  const windowGlobal = typeof window !== "undefined" && window

  const devtools =
    process.env.NODE_ENV === "development" && windowGlobal.devToolsExtension
      ? window.__REDUX_DEVTOOLS_EXTENSION__ &&
        window.__REDUX_DEVTOOLS_EXTENSION__()
      : f => f

  const store = createStore(
    rootReducer,
    compose(applyMiddleware(...middleware), devtools)
  )

  return store
}
