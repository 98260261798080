// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-claim-js": () => import("./../../src/pages/claim.js" /* webpackChunkName: "component---src-pages-claim-js" */),
  "component---src-pages-contact-js": () => import("./../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-contact-success-js": () => import("./../../src/pages/contact/success.js" /* webpackChunkName: "component---src-pages-contact-success-js" */),
  "component---src-pages-get-quote-details-js": () => import("./../../src/pages/get-quote-details.js" /* webpackChunkName: "component---src-pages-get-quote-details-js" */),
  "component---src-pages-get-quote-policies-js": () => import("./../../src/pages/get-quote-policies.js" /* webpackChunkName: "component---src-pages-get-quote-policies-js" */),
  "component---src-pages-get-quote-purchase-fail-js": () => import("./../../src/pages/get-quote-purchase/fail.js" /* webpackChunkName: "component---src-pages-get-quote-purchase-fail-js" */),
  "component---src-pages-get-quote-purchase-js": () => import("./../../src/pages/get-quote-purchase.js" /* webpackChunkName: "component---src-pages-get-quote-purchase-js" */),
  "component---src-pages-get-quote-purchase-payment-info-js": () => import("./../../src/pages/get-quote-purchase/payment-info.js" /* webpackChunkName: "component---src-pages-get-quote-purchase-payment-info-js" */),
  "component---src-pages-get-quote-purchase-payment-js": () => import("./../../src/pages/get-quote-purchase/payment.js" /* webpackChunkName: "component---src-pages-get-quote-purchase-payment-js" */),
  "component---src-pages-get-quote-purchase-review-js": () => import("./../../src/pages/get-quote-purchase/review.js" /* webpackChunkName: "component---src-pages-get-quote-purchase-review-js" */),
  "component---src-pages-get-quote-purchase-success-js": () => import("./../../src/pages/get-quote-purchase/success.js" /* webpackChunkName: "component---src-pages-get-quote-purchase-success-js" */),
  "component---src-pages-get-quote-travellers-js": () => import("./../../src/pages/get-quote-travellers.js" /* webpackChunkName: "component---src-pages-get-quote-travellers-js" */),
  "component---src-pages-get-quote-travellers-mq-js": () => import("./../../src/pages/get-quote-travellers-mq.js" /* webpackChunkName: "component---src-pages-get-quote-travellers-mq-js" */),
  "component---src-pages-in-the-news-js": () => import("./../../src/pages/in-the-news.js" /* webpackChunkName: "component---src-pages-in-the-news-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-terms-js": () => import("./../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-test-js": () => import("./../../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */),
  "component---src-pages-view-my-policy-js": () => import("./../../src/pages/view-my-policy.js" /* webpackChunkName: "component---src-pages-view-my-policy-js" */)
}

