import React from "react"
import { css } from "@emotion/core"
import { useIntl } from "react-intl"

import { Title, SectionCenter, Card } from "../components"
import purposeCards from "../utils/purposeCards"
import { mq } from "../utils/media-query"

const GetQuotePurpose = () => {
  return (
    <section
      css={css`
        padding: 5rem 0;
      `}
    >
      <p
        css={css`
          text-align: center;
          color: var(--clr-primary-500);
        `}
      >
        {useIntl().formatMessage({ id: "home.purposeSub" })}
      </p>
      <Title title={useIntl().formatMessage({ id: "home.purposeCap" })} />
      <SectionCenter>
        <div
          css={css`
            max-width: 1170px;
            margin: 0 auto 1rem;
            display: grid;
            row-gap: 2rem;
            text-align: center;
            grid-template-rows: 420px 420px 420px 420px;
            @media (min-width: 600px) {
              width: 100%;
              grid-template-columns: 1fr 1fr;
              grid-template-rows: 420px 420px;
              column-gap: 2rem;
              row-gap: 2rem;
              justify-items: center;
            }
            /* 992px */
            ${mq[1]} {
              width: 100%;
              grid-template-columns: 1fr 1fr 1fr 1fr;
              grid-template-rows: 420px;
              justify-items: center;
              align-items: center;
              column-gap: 1rem;
            }
          `}
        >
          {purposeCards.map(cardItem => (
            <Card key={cardItem.label} {...cardItem} />
          ))}
        </div>
      </SectionCenter>
    </section>
  )
}

export default GetQuotePurpose
