import styled from "@emotion/styled"

const LabelStyled = styled.label`
  color: var(--clr-primary-300);
  font-family: ${props => props.fontFamily || "var(--ff-tertiary)"};
  font-size: ${props => props.fontSize || "1.1rem"};
  text-transform: ${props => props.textTransform || "capitalize"};
  font-weight: ${props => props.fontWeight || "bold"};
  letter-spacing: var(--letter-spacing-sm);
  margin-bottom: ${props => props.marginBottom || "0.5rem"};
  display: block;
  text-align: ${props => props.textAlign};
  padding: ${props => props.padding};
  width: ${props => props.width};
`

export default LabelStyled
